// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard-role-modal__description textarea.ant-input {
  height: 71px;
}
.standard-role-modal__visibility .ant-form-item-row {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}
.standard-role-modal__visibility .ant-form-item-label {
  padding-bottom: 0;
  line-height: 32px;
}
.standard-role-modal__visibility .ant-form-item-label > label {
  color: #52555c;
}
.standard-role-modal__visibility .ant-form-item-control-input,
.standard-role-modal__visibility .ant-form-item-control {
  max-width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_assignment/components/standard_role_modal/standard_role_modal.less"],"names":[],"mappings":"AAGE;EAEI,YAAA;AAHN;AASM;EACE,aAAA;EACA,sCAAA;EACA,yBAAA;AAPR;AAUM;EACE,iBAAA;EACA,iBAAA;AARR;AAUQ;EACE,cAAA;AARV;AAYM;;EAEE,eAAA;AAVR","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.standard-role-modal {\n  &__description {\n    textarea.ant-input {\n      height: 71px;\n    }\n  }\n\n  &__visibility {\n    .ant-form-item {\n      &-row {\n        display: flex;\n        flex-direction: row-reverse !important;\n        justify-content: flex-end;\n      }\n\n      &-label {\n        padding-bottom: 0;\n        line-height: 32px;\n\n        & > label {\n          color: @font-01;\n        }\n      }\n\n      &-control-input,\n      &-control {\n        max-width: 50px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
