import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./update_standard_role_modal.less";
import api from "../../../../api";
import { SelectionRenderer } from "../../../../custom_renderer";
import { gridOptions } from "../../utils/update_standard_role_modal_grid_options";
import { getInitialBasicRoleName } from "../../utils/update_standard_role_modal_utils";

const UpdateStandardRoleModal = ({
  requests,
  modalVisible,
  setModalVisible,
  handleSave,
  handleCancel,
}) => {
  const gridApi = useRef(null);
  const gridRef = useRef(null);

  const [
    standardRolesGroupedByBasicRoles,
    setStandardRolesGroupedByBasicRoles,
  ] = useState([]);

  useEffect(() => {
    const fetchStandardRoles = async () => {
      const res = await api.get(
        "/standard-roles/grouped-by-basic-role",
        {},
        "Failed to get standard roles"
      );

      if (res?.data) {
        gridOptions.context = {
          basicRoles: res.data,
        };
        setStandardRolesGroupedByBasicRoles(res.data);
        gridApi.current?.redrawRows();
      }
    };

    if (modalVisible) {
      fetchStandardRoles();
    }
  }, [modalVisible]);

  useEffect(() => {
    // if basic role can be determined by the given data, it will be initially set
    requests.forEach((request) => {
      request.basicRole = getInitialBasicRoleName(
        standardRolesGroupedByBasicRoles,
        request.standardRole
      );
    });
  }, [requests, standardRolesGroupedByBasicRoles]);

  /**
   * resets all data and the csv upload field
   */
  const onCancel = () => {
    handleCancel();
    setModalVisible(false);
    // don't know any other way to reset csv upload field
    document.getElementById("csv-cancel-btn").click();
  };

  /**
   * onChange function for Basic Role Selection Renderer
   * @param {*} value new value
   * @param {*} props agGrid props
   */
  const changeBasicRole = (value, props) => {
    updateData(value, props);
    props.node.setDataValue("specification", undefined);
    gridApi.current.redrawRows({ rowNodes: [props.node] });
  };

  /**
   * updates the node data
   * @param {*} value new value
   * @param {*} props agGrid props
   */
  const updateData = (value, props) => {
    const field = props.colDef.field;
    props.node.setDataValue(field, value);
  };

  const frameworkComponents = {
    basicRoleRenderer: (props) => {
      return (
        <SelectionRenderer
          props={props}
          dataToExtractOptions={props.context.basicRoles.map(
            (role) => role.basicRoleName
          )}
          defaultValue={props.data.basicRole}
          placeholder="Select Basic Role"
          onChangeCallback={changeBasicRole}
          ariaLabel="basic-role-renderer"
        />
      );
    },
    roleSpecificationRenderer: (props) => {
      return (
        <SelectionRenderer
          props={props}
          dataToExtractOptions={props.value}
          placeholder="Select Specification"
          onChangeCallback={updateData}
          defaultValue={
            props.data.specification || undefined // it could be an empty string. to show the placeholder text it needs to be undefined
          }
          disabled={props.value.length === 0}
          ariaLabel="role-specification-renderer"
        />
      );
    },
  };

  const onGridReady = (props) => {
    gridApi.current = props.api;
    gridApi.current.sizeColumnsToFit();
  };

  return (
    <Modal
      className="update-standard-role-modal"
      width="1100px"
      open={modalVisible}
      closable={false}
      maskClosable={false}
      cancelText="Cancel"
      onCancel={onCancel}
      okText="Save"
      onOk={() => handleSave(gridRef.current.props.rowData)}
    >
      <div className="ag-theme-alpine header-white">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={requests}
          gridOptions={gridOptions}
          components={frameworkComponents}
        />
      </div>
    </Modal>
  );
};

export default UpdateStandardRoleModal;
