const cellWrapText = {
  lineHeight: "20px",
  whiteSpace: "normal",
};

export const columnDefs = [
  {
    headerName: "Region",
    field: "regionName",
    cellRenderer: "inputRenderer",
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Actions",
    field: "actions",
    suppressSizeToFit: true,
    width: 350,
    pinned: "right",
    cellRenderer: "actionsRenderer",
    sortable: false,
  },
];

export const getGridOptions = () => ({
  domLayout: "autoHeight",
  suppressMenuHide: true,
  suppressContextMenu: true,
  suppressClickEdit: true,
  defaultColDef: {
    cellStyle: cellWrapText,
    editable: false,
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    suppressMovable: true,
    resizable: true,
  },
  pagination: true,
  paginationPageSize: 10,
  animateRows: true,
  rowHeight: 55,
  context: {
    region: [],
  },
});
