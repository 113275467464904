import { useEffect, useRef, useState } from "react";

import { Select } from "antd";
import { ICellRendererParams } from "ag-grid-community";

export interface ISelectionRenderer {
  props?: ICellRendererParams;
  allowClear?: boolean;
  dataToExtractOptions?: any[];
  propertyForOptionValues?: string;
  propertyForOptionLabels?: string;
  propertyForOptionDisable?: string;
  defaultValue?: string;
  style?: any;
  placeholder?: string;
  isMultiple?: boolean;
  onChangeCallback?: any;
  disabled?: boolean;
  hideSelection?: boolean;
  fallBackMessage?: string;
  titleMessage?: string;
  ariaLabel?: string;
  hideWhenFocusLostCallback?: any;
  isLoading?: boolean;
}

function SelectionRenderer({
  props,
  allowClear,
  dataToExtractOptions,
  propertyForOptionValues,
  propertyForOptionLabels,
  propertyForOptionDisable,
  defaultValue,
  style,
  placeholder,
  isMultiple,
  onChangeCallback,
  disabled,
  hideSelection,
  fallBackMessage,
  ariaLabel,
  hideWhenFocusLostCallback,
  isLoading,
}: ISelectionRenderer) {
  const wrapperRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // new state
  const isDropdownOpenRef = useRef(isDropdownOpen); // Create a ref for the state

  useEffect(() => {
    isDropdownOpenRef.current = isDropdownOpen; // Update ref whenever state changes
  }, [isDropdownOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideWhenFocusLostCallback]);

  function handleClickOutside(event) {
    if (
      wrapperRef.current != null &&
      !wrapperRef.current.contains(event.target) &&
      !isDropdownOpenRef.current &&
      hideWhenFocusLostCallback
    ) {
      hideWhenFocusLostCallback();
    }
  }

  const generateOptions = () => {
    // check if values only contain strings
    if (dataToExtractOptions?.every((value) => typeof value === "string"))
      return dataToExtractOptions.map((value) => ({
        value: value,
        label: value,
        id: value,
      }));

    const options = [];

    if (dataToExtractOptions) {
      for (const option of dataToExtractOptions) {
        options.push({
          value: option[propertyForOptionValues],
          label: option[propertyForOptionLabels],
          disabled: propertyForOptionDisable
            ? option[propertyForOptionDisable]
            : false,
          id: option[propertyForOptionLabels],
        });
      }
    }

    return options;
  };

  function onChange(value, option) {
    const dataObject = dataToExtractOptions.find(
      (data) => data[propertyForOptionValues] === value
    );
    onChangeCallback(value, props, option, dataObject);
  }

  return !hideSelection ? (
    <span ref={wrapperRef}>
      <Select
        allowClear={allowClear}
        showSearch
        aria-label={ariaLabel || "selection-renderer"}
        optionFilterProp="children"
        data-testid="selection-renderer"
        mode={isMultiple ? "multiple" : null}
        defaultValue={defaultValue}
        style={style}
        disabled={disabled}
        onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
        placeholder={placeholder}
        onChange={onChange}
        options={generateOptions()}
        loading={isLoading}
        filterOption={(input, option) =>
          //filter options are needed for autocomplete search
          option?.label
            ?.toLowerCase()
            .indexOf(input?.toString()?.toLowerCase()) >= 0
        }
      />
    </span>
  ) : (
    <p>{fallBackMessage}</p>
  );
}

export default SelectionRenderer;
