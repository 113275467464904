import React from "react";

import { FileExclamationOutlined } from "@ant-design/icons";
import { FilterDropdown } from "@src/components/filter_dropdown";
import { FilterGroup } from "@src/components/filter_group";
import { RoleAssignmentFilterData } from "@src/types";
import { TreeNode } from "@src/types/antd_types";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import { GridApi } from "ag-grid-community";
import { Button } from "antd";

import "./role_assignment_filter.less";

export interface RoleAssignmentFilterProps {
  gridApi: GridApi;
  filterData: RoleAssignmentFilterData;
  disabled: boolean;
}

const RoleAssignmentFilter: React.FC<RoleAssignmentFilterProps> = ({
  gridApi,
  filterData,
  disabled,
}) => {
  let clearFilterBasicRoles: () => void;
  let clearFilterRoleSpecifications: () => void;

  /**
   * Gets the reset function from the filterDropdown component
   * The reset button (that is the parent of filterDropdown) can call the reset function
   *
   * @param {() => void} resetFilterFunction reset function of filterDropdown for basicRoles
   */
  function assignResetBasicRoleFunction(resetFilterFunction: () => void) {
    clearFilterBasicRoles = resetFilterFunction;
  }

  /**
   * Gets the reset function from the filterDropdown component
   * The reset button (that is the parent of filterDropdown) can call the reset function
   *
   * @param {() => void} resetFilterFunction reset function of filterDropdown for roleSpecifications
   */
  function assignResetRoleSpecificationFunction(
    resetFilterFunction: () => void
  ) {
    clearFilterRoleSpecifications = resetFilterFunction;
  }

  const basicRoleOptions: TreeNode[] = mapToTreeData(
    filterData?.basicRoles,
    "basicRoleName"
  );

  const roleSpecificationOptions: TreeNode[] = mapToTreeData(
    filterData?.roleSpecifications,
    "roleSpecificationName"
  );

  /**
   * filter callback for the quick search filter input
   *
   * @param {string} searchText text of quick search input
   */
  function onFilterQuickSearch(searchText: string): void {
    gridApi.setQuickFilter(searchText);
  }

  /**
   * reset filter function
   */
  function onClickResetFilter(): void {
    gridApi.setFilterModel(null);
    gridApi.setQuickFilter("");
    clearFilterBasicRoles?.();
    clearFilterRoleSpecifications?.();
  }

  /**
   * onFilterChange function of the filterDropdown
   * Updates the Ag Grid filter model
   *
   * @param {string} colId colId of the Ag Grid ColDef (if no colId is set separately, it's the same as the field)
   * @param {string[]} values values chosen in the filterDropdown
   */
  const onFilterChange = (colId: string, values: string[]): void => {
    gridApi?.getFilterInstance(colId).setModel({ values: values });
    gridApi?.onFilterChanged();
  };

  return (
    <div className="role-assignment-filter">
      <FilterGroup
        onFilterQuickSearch={onFilterQuickSearch}
        onReset={onClickResetFilter}
        data-testid="filterGroup"
        disabled={disabled}
      >
        {filterData?.basicRoles && (
          <FilterDropdown
            placeholderDropdown="Basic Role Filter"
            placeholderSearch="Enter Basic Role"
            treeData={basicRoleOptions}
            onFilterChange={(values: string[]) =>
              onFilterChange("basicRole", values)
            }
            assignResetFunction={assignResetBasicRoleFunction}
            disabled={disabled}
          />
        )}

        {filterData?.roleSpecifications && (
          <FilterDropdown
            placeholderDropdown="Specification Filter"
            placeholderSearch="Enter Specification"
            treeData={roleSpecificationOptions}
            onFilterChange={(values: string[]) =>
              onFilterChange("roleSpecification", values)
            }
            assignResetFunction={assignResetRoleSpecificationFunction}
            disabled={disabled}
          />
        )}
      </FilterGroup>

      {filterData?.standardRoles && (
        <Button
          shape="round"
          type="primary"
          className="role-assignment-filter__team-leads"
          data-testid="error-btn"
          danger
          onClick={() => onFilterChange("teamLead", [undefined])}
          icon={<FileExclamationOutlined />}
          disabled={disabled}
        >
          No Team Lead:&nbsp; <b>{filterData?.numberOfErrors}</b>
        </Button>
      )}
    </div>
  );
};

export default RoleAssignmentFilter;
