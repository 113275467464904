import { TreeNode } from "@src/types/antd_types";
import { getDistinctList } from "@src/utils/helper";
import { ColDef, ColGroupDef } from "ag-grid-community";
import _ from "lodash";
import moment from "moment";

/**
 * filters the treeData based on the given search text
 * @param {TreeNode[]} treeData treeData to be filtered
 * @param {string} searchText text that the filtering is based on
 * @returns filtered treeData
 */
export function getFilteredTreeData(
  treeData: TreeNode[],
  searchText: string
): TreeNode[] {
  if (!searchText) return treeData;
  const filteredOptions: TreeNode[] = [];
  treeData.forEach((treeItem: TreeNode) => {
    const treeItemTitle = treeItem.title.toString();
    if (treeItemTitle.toLowerCase().includes(searchText.toLowerCase())) {
      filteredOptions.push(treeItem);
    } else if (treeItem.children) {
      const filteredChildren: TreeNode[] = treeItem.children.filter(
        (child: TreeNode) =>
          child.title
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      if (filteredChildren.length > 0) {
        const newOption: TreeNode = _.cloneDeep(treeItem);
        newOption.children = filteredChildren;
        filteredOptions.push(newOption);
      }
    }
  });
  return filteredOptions;
}

/**
 * loops through an array with options (that can also contain children) and
 * search for the value in the given property
 * @param {T[]} data array with objects
 * @param {string} property property to search the array for
 * @returns an array with all values of the given property
 */
export function getPropertyValuesOfData<T>(data: T[], property: string): any[] {
  let newArray: any[] = [];
  data.forEach((item: T) => {
    newArray.push(item[property]);
    if (item["children"]) {
      newArray = newArray.concat(
        item["children"].map((child: T) => child[property])
      );
    }
  });
  return newArray;
}

/**
 * Converts an array of data into an array of tree nodes for use in a tree component.
 *
 * @template T
 * @param {T[]} data - The array of data items to convert.
 * @param {string} [property] - Optional property name to extract from each data item. If not provided, the data items themselves are used.
 * @returns {TreeNode[]} - An array of tree nodes with distinct and sorted titles.
 */
export function mapToTreeData<T>(data: T[], property?: string): TreeNode[] {
  if (!data) return [];

  let options: string[] = property
    ? data.map((item: T) => item[property])
    : data;
  options = getDistinctList(options).sort();

  return options
    .filter((option: string) => !!option)
    .map((option: string) => ({
      title: option,
      key: option,
    }));
}

/**
 * Sorts two TreeNode objects based on their key property. If the key contains an underscore,
 * it extracts the numeric part after the underscore and sorts based on that number. Otherwise,
 * it performs a standard alphabetical comparison of the key property.
 *
 * @param {TreeNode} a - The first TreeNode object to compare.
 * @param {TreeNode} b - The second TreeNode object to compare.
 * @returns {number} - A negative number if `a` should come before `b`, a positive number if `a` should come after `b`, or zero if they are equal.
 */
function sortTree(a: TreeNode, b: TreeNode): number {
  if (a.key.includes("_") && b.key.includes("_")) {
    const aIndex = parseInt(a.key.split("_")[1]);
    const bIndex = parseInt(b.key.split("_")[1]);
    return aIndex - bIndex;
  }
  return a.key.localeCompare(b.key);
}

/**
 * Converts an array of data into an array of tree nodes for use in a tree component.
 *
 * @template T
 * @param {ColDef[] | ColGroupDef[]} data - The array of data items to convert.
 * @param {string} [property] - Optional property name to extract from each data item. If not provided, the data items themselves are used.
 * @returns {TreeNode[]} - An array of tree nodes with distinct and sorted titles.
 */
export function mapColumnsToTreeData(
  data: (ColDef | ColGroupDef)[],
  property?: string
): TreeNode[] {
  if (!data) return [];

  // Extract titles from the data array
  let options: string[] = property
    ? data.map((item: ColDef | ColGroupDef) => item[property])
    : (data as unknown as string[]);
  options = getDistinctList(options).sort();

  // Helper function to extract child nodes
  const extractChildren = (item: ColGroupDef): TreeNode[] => {
    if (item.children) {
      return mapColumnsToTreeData(item.children, property);
    }
    return [];
  };

  // Map options to tree nodes and include children if present
  return options
    .filter((option: string) => !!option)
    .map((option: string) => {
      const item: ColDef | ColGroupDef = data.find(
        (d: ColDef | ColGroupDef) => (property ? d[property] : d) === option
      );
      if (!item) {
        return null;
      }
      return {
        title: item.headerName,
        key: item["colKey"] || option,
        children:
          "children" in item ? extractChildren(item as ColGroupDef) : [],
      };
    })
    .sort((a, b) => sortTree(a, b))
    .filter((node: TreeNode | null): node is TreeNode => node !== null);
}

/**
 * This function is used to format the date filter for the active filter, so that it is displayed in the active filters bar.
 * The date filter is an array of two dates, so the function will format the first date and then the second date.
 * e.g [2023-02-01, 2023-02-10] will be formatted to 01/02/2023 - 10/02/2023
 *
 * @param dates the date filter array
 * @returns the formatted date filter
 */
export function formatDateActiveFilter(dates: string[]): string {
  return (
    moment(dates[0]).format("DD/MM/YYYY") +
    " - " +
    moment(dates[1]).format("DD/MM/YYYY")
  );
}

/**
 * Get all dates in the range.
 *
 * @param startDate {string}   start date of the workload (optional)
 * @param endDate {string}     end date of the workload (optional)
 * @param datesToCheck {string[]} dates to check if they are in the range
 * @returns {Object} An object with a boolean indicating if a filter was applied and an array of dates
 */
export function getAllDatesInRange(
  startDate?: string,
  endDate?: string,
  datesToCheck?: string[]
): { dates: string[]; filtered: boolean } {
  // If both startDate and endDate are undefined, no filter is applied
  if (!startDate && !endDate) {
    return { filtered: false, dates: [] };
  }

  const startDateMoment = startDate ? moment(startDate) : null;
  const endDateMoment = endDate ? moment(endDate) : null;
  const dates: string[] = [];

  for (const dateToCheck of datesToCheck) {
    if (isDateInRange(dateToCheck, startDateMoment, endDateMoment)) {
      dates.push(moment(dateToCheck).format("DD/MM/YYYY"));
    }
  }

  return { filtered: true, dates };
}

export const isDateInRange = (
  date: string,
  rangeStartDate: moment.Moment,
  rangeEndDate: moment.Moment
): boolean => {
  return moment(date).isBetween(rangeStartDate, rangeEndDate, "days", "[]");
};
