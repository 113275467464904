import { MsalProvider } from "@azure/msal-react";
import { LocationProvider } from "@reach/router";
import { setupStore } from "@src/setupStore";
import { ConfigProvider, App as AntApp } from "antd";
import { Provider } from "react-redux";

import "./App.css";
import { msalInstance } from "./auth";
import { AppLayout } from "./components/app_layout";
import ErrorBoundary from "./components/error_boundary";
import { ValidateAuth } from "./components/login/";
import { extendDaysjsPlugins } from "./dayjs_plugins";
import { CustomRouter } from "./router";
import { defaultTheme } from "./stylings/default_theme";
import { initializeFaro } from "./utils/faro";

extendDaysjsPlugins();

initializeFaro();

function App() {
  return (
    <ConfigProvider theme={defaultTheme}>
      <AntApp>
        <Provider store={setupStore({})}>
          <div className="app-container">
            <MsalProvider instance={msalInstance}>
              <ErrorBoundary>
                <LocationProvider>
                  <ValidateAuth>
                    <AppLayout> {<CustomRouter />}</AppLayout>
                  </ValidateAuth>
                </LocationProvider>
              </ErrorBoundary>
            </MsalProvider>
          </div>
        </Provider>
      </AntApp>
    </ConfigProvider>
  );
}

export default App;
