import React from "react";

import ProjectPhaseRenderer from "@src/features/staffing_requests/custom_renderer/project_phase_renderer/project_phase_renderer";
import RegionCountryRenderer from "@src/features/staffing_requests/custom_renderer/region_country_renderer/region_country_renderer";
import RequestedRoleRenderer from "@src/features/staffing_requests/custom_renderer/requested_role_renderer/requested_role_renderer";
import StatusRenderer from "@src/features/staffing_requests/custom_renderer/status_renderer/status_renderer";
import { TeamLeadRequestOverviewItem } from "@src/types";
import {
  CellStyle,
  ColDef,
  GridOptions,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { GetQuickFilterTextParams } from "ag-grid-community/dist/lib/entities/colDef";
import moment from "moment";

import { getBadgeVariant } from "./request_overview_utils";
import {
  TagRenderer,
  ActionRequiredRenderer,
  DetailsRenderer,
} from "../custom_renderer";
import { OutdatedBadge } from "@src/components/outdated_badge";

const cellWrapText: CellStyle = {
  lineHeight: "20px",
  whiteSpace: "normal",
};
const centerCellText: CellStyle = { justifyContent: "center" };

function getFormattedDate(date: Date): string {
  return moment(date).format("DD/MM/YYYY");
}

function frameworkComponents(): any {
  return {
    renderRequestedRole: (params: ICellRendererParams) => {
      return <RequestedRoleRenderer params={params} />;
    },
    renderRegionCountry: (params: ICellRendererParams) => {
      return <RegionCountryRenderer params={params} />;
    },
    renderProjectPhase: (params: ICellRendererParams) => {
      return <ProjectPhaseRenderer params={params} />;
    },
    renderTag: (params: ICellRendererParams) => {
      return <TagRenderer variant={params.value} />;
    },
    renderOutdatedBadge: (params: ICellRendererParams) => {
      return (
        <OutdatedBadge daysUntilDeletion={params.data.daysUntilDeletion} />
      );
    },
    renderRequiredAction: (params: ICellRendererParams) => {
      return <ActionRequiredRenderer actionRequired={params.value} />;
    },
    renderStatus: (params: ICellRendererParams) => {
      return (
        <StatusRenderer
          status={params.data.status}
          assignedTeamMember={params.data.assignedTeamMember}
        />
      );
    },
    renderDetails: (params: ICellRendererParams) => {
      return <DetailsRenderer projectRoleRequestId={params.value} />;
    },
  };
}

const projectNameColDef: ColDef = {
  headerName: "Project Name",
  field: "projectName",
  cellStyle: cellWrapText,
  minWidth: 100,
  autoHeight: true,
  resizable: true,
  filter: "agSetColumnFilter",
};

const requestIdColDef: ColDef = {
  headerName: "Request ID",
  field: "projectRoleRequestId",
  cellStyle: cellWrapText,
  wrapHeaderText: true,
  minWidth: 100,
  maxWidth: 250,
  autoHeight: true,
  wrapText: true,
  resizable: true,
};

const tagColDef: ColDef = {
  headerName: "Tag",
  field: "tag",
  cellRenderer: "renderTag",
  width: 130,
  suppressSizeToFit: true,
  valueGetter: (props) => {
    return getBadgeVariant(props);
  },
  cellRendererSelector: (params: ICellRendererParams) =>
    params.value === "Outdated"
      ? { component: "renderOutdatedBadge" }
      : { component: "renderTag" },
};

const requestedRoleColDef: ColDef = {
  headerName: "Requested Role",
  field: "requestedRoleName",
  cellRenderer: "renderRequestedRole",
  cellStyle: cellWrapText,
  minWidth: 370,
  autoHeight: true,
  resizable: true,
  filter: "agSetColumnFilter",
  getQuickFilterText: (
    params: GetQuickFilterTextParams<TeamLeadRequestOverviewItem>
  ) => {
    return `${params.data.projectRoleRequestId} ${params.data.requestedRoleName}`;
  },
  cellClass: "full-width-cell",
};

const projectPhaseColDef: ColDef = {
  headerName: "Project Phase",
  field: "projectPhase",
  cellStyle: cellWrapText,
  minWidth: 100,
  maxWidth: 350,
  autoHeight: true,
  resizable: true,
  filter: "agSetColumnFilter",
};

const technologiesColDef: ColDef = {
  headerName: "Technology",
  field: "technologies",
  cellStyle: cellWrapText,
  minWidth: 100,
  maxWidth: 250,
  resizable: true,
  filter: "agSetColumnFilter",
};

const projectAndPhaseColDef: ColDef = {
  headerName: "Project/Phase",
  cellRenderer: "renderProjectPhase",
  field: "projectNameAndPhase",
  cellStyle: cellWrapText,
  minWidth: 400,
  maxWidth: 420,
  autoHeight: true,
  resizable: true,
  filter: "agSetColumnFilter",
  getQuickFilterText: (
    params: GetQuickFilterTextParams<TeamLeadRequestOverviewItem>
  ) => {
    return `${params.data.country} ${params.data.region}`;
  },
  valueGetter: (params: ValueGetterParams) => {
    return `${params.data.projectName} ${params.data.projectPhase}`;
  },
  cellClass: "full-width-cell",
};

const countryColDef: ColDef = {
  headerName: "Country",
  field: "country",
  cellStyle: cellWrapText,
  minWidth: 100,
  maxWidth: 350,
  autoHeight: true,
  resizable: true,
  filter: "agSetColumnFilter",
};

const regionColDef: ColDef = {
  headerName: "Region",
  field: "region",
  cellStyle: cellWrapText,
  minWidth: 100,
  maxWidth: 350,
  autoHeight: true,
  resizable: true,
  filter: "agSetColumnFilter",
};

const regionCountryColDef: ColDef = {
  headerName: "Region/Country",
  field: "regionAndCountry",
  cellRenderer: "renderRegionCountry",
  cellStyle: cellWrapText,
  minWidth: 232,
  maxWidth: 350,
  autoHeight: true,
  resizable: true,
  filter: "agSetColumnFilter",
  getQuickFilterText: (
    params: GetQuickFilterTextParams<TeamLeadRequestOverviewItem>
  ) => {
    return `${params.data.country} ${params.data.region}`;
  },
  valueGetter: (params: ValueGetterParams) => {
    return `${params.data.region} ${params.data.country}`;
  },
};

const workloadStartColDef: ColDef = {
  headerName: "WL Start",
  field: "roleRequestStart",
  headerTooltip: "Workload Start Date",
  valueFormatter: (params: ValueFormatterParams) => {
    if (params.data?.roleRequestStart) {
      return getFormattedDate(params.data.roleRequestStart);
    }
    return "N/A";
  },
  filterValueGetter: (params: ValueGetterParams) => {
    return getFormattedDate(params.data.roleRequestStart);
  },
  minWidth: 150,
  maxWidth: 200,
  filter: "agSetColumnFilter",
};

const requestSentColDef: ColDef = {
  headerName: "Request Sent",
  field: "requestSentDate",
  valueFormatter: (params: ValueFormatterParams) => {
    if (params.data?.requestSentDate) {
      return getFormattedDate(params.data.requestSentDate);
    }
    return "N/A";
  },
  filterValueGetter: (params: ValueGetterParams) => {
    return getFormattedDate(params.data.requestSentDate);
  },
  minWidth: 150,
  maxWidth: 200,
};

const statusColDef: ColDef = {
  headerName: "Status",
  cellRenderer: "renderStatus",
  field: "status",
  minWidth: 128,
  maxWidth: 150,
  filter: "agSetColumnFilter",
};

const requiredActionColDef: ColDef = {
  headerName: "Required Action",
  field: "actionRequired",
  cellRenderer: "renderRequiredAction",
  cellStyle: centerCellText,
  minWidth: 100,
  maxWidth: 180,
  resizable: true,
  filter: "agSetColumnFilter",
};

function getColumns(): ColDef[] {
  return [
    requestIdColDef,
    requestedRoleColDef,
    projectNameColDef,
    tagColDef,
    projectPhaseColDef,
    technologiesColDef,
    projectAndPhaseColDef,
    regionCountryColDef,
    countryColDef,
    regionColDef,
    workloadStartColDef,
    requestSentColDef,
    statusColDef,
    requiredActionColDef,
  ];
}

export function createGridOptions(): GridOptions {
  return {
    columnDefs: getColumns(),
    components: frameworkComponents(),
    rowHeight: 50,
    domLayout: "autoHeight",
    suppressMenuHide: true,
    suppressContextMenu: true,
    pagination: true,
    paginationPageSize: 20,
    alwaysMultiSort: true,
    defaultColDef: {
      editable: false,
      sortable: true,
      unSortIcon: true,
      suppressMenu: true,
      suppressMovable: true,
    },
  };
}
