import minussquare from "@src/assets/icons/minussquare.png";
import plussquare from "@src/assets/icons/plussquare.png";

const getFullWidthRowHeight = (proposedData) => {
  if (
    proposedData.proposedYearlyAllocations &&
    Object.keys(proposedData.proposedYearlyAllocations).length > 0 &&
    !proposedData.isOutdated
  ) {
    return 900;
  }
  return 520;
};

export const requestRepliesOptions = {
  pagination: true,
  suppressContextMenu: true,
  paginationPageSize: 10,
  domLayout: "autoHeight",
  groupUseEntireRow: true,
  icons: {
    groupExpanded: `<img src=${minussquare} style="height: 25px; width: 25px;padding-right: 2px"/>`,
    groupContracted: `<img src=${plussquare} style="height: 25px; width: 25px;padding-right: 2px"/>`,
  },
  defaultColDef: {
    flex: 1,
    suppressMovable: true,
  },
  getRowClass: (params) => {
    if (
      params.data.status === "Declined" ||
      params.data.status === "Proposed" ||
      params.node.rowPinned
    ) {
      return `row-status-bold`;
    }
  },
  getRowHeight: (params) => {
    if (params.node.detail) {
      return getFullWidthRowHeight(params.node.data);
    } else {
      return 55;
    }
  },
};
