import { EndpointBuilder } from "@reduxjs/toolkit/src/query/endpointDefinitions";
import { message } from "antd";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { createFetchBaseQuery } from "@src/services/slices/baseQuery";
import { NotificationsCount } from "@src/types";

const getEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  return {
    /**
     * Load notifications
     */
    getNotificationsCount: builder.query<NotificationsCount[], void>({
      query: () => "/layout/getTabNotificationCount",
      transformErrorResponse: async () => {
        message.error("Failed to load notifications", 5);
      },
      providesTags: ["Notifications"],
    }),
  };
};

export const layoutSlice = createApi({
  reducerPath: "layoutSlice",
  baseQuery: createFetchBaseQuery(""),
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /*GET endpoints */
    ...getEndpoints(builder),
  }),
});

export const {
  /*GET endpoints */
  useGetNotificationsCountQuery,
} = layoutSlice;
