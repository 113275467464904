import React, { useCallback, useRef, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { createGridOptions } from "@src/features/role_assignment/utils/role_specifications_management_grid_options";
import {
  useAddRoleSpecificationMutation,
  useDeleteRoleSpecificationMutation,
  useGetRoleSpecificationsQuery,
  useUpdateRoleSpecificationMutation,
} from "@src/services/slices/roleSpecificationsApi";
import { RoleSpecification } from "@src/types";
import {
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { App, Button } from "antd";
import _ from "lodash";

import { RoleTypes } from "../../utils/role_assignment_constants";
import { showSuccessMessage } from "../../utils/role_assignment_helper";
import { RoleAssignmentFilter } from "../role_assignment_filter";
import { RoleSpecificationModal } from "../role_specification_modal";

const RoleSpecificationManagement: React.FC = () => {
  const gridApi = useRef<GridApi>(null);

  const { message } = App.useApp();

  /* use states */
  const [showUpdateRoleModal, setShowUpdateRoleModal] =
    useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  /* rtk queries and mutations */
  const { data: roleSpecifications, isFetching } =
    useGetRoleSpecificationsQuery();
  const [addRoleSpecification] = useAddRoleSpecificationMutation();
  const [deleteRoleSpecification] = useDeleteRoleSpecificationMutation();
  const [updateRoleSpecification] = useUpdateRoleSpecificationMutation();

  /* -------- AG Grid functions --------  */

  /**
   * AG Grid onGridReady function
   * assigns the GridApi to a ref and resizes the columns so that the table is displayed correctly
   * also enables the filter bar and add button when table is ready
   *
   * @param {GridReadyEvent} event AG Grid event
   */
  const onGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
    event.api.sizeColumnsToFit();
    setIsTableLoading(false);
  }, []);

  /* -------- click events --------  */

  /**
   * opens a modal where the user can add a new role
   */
  function onAddNewRow(): void {
    setShowUpdateRoleModal(true);
  }

  /**
   * makes a post request to create a new role specification
   *
   * @param {RoleSpecification} newRoleSpecification the role specification to create
   */
  function onSaveRoleModal(newRoleSpecification: RoleSpecification) {
    addRoleSpecification(newRoleSpecification)
      .unwrap()
      .then(() => {
        showSuccessMessage("created", message, RoleTypes.STANDARD_ROLE);
        setShowUpdateRoleModal(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  return (
    <>
      {showUpdateRoleModal && (
        <RoleSpecificationModal
          showModal={showUpdateRoleModal}
          setShowModal={setShowUpdateRoleModal}
          onSaveCallback={onSaveRoleModal}
        />
      )}
      <div className="top-section" data-testid="top-section">
        <RoleAssignmentFilter
          gridApi={gridApi.current}
          filterData={{
            roleSpecifications: roleSpecifications,
          }}
          disabled={isFetching || isTableLoading}
        />
        <Button
          onClick={onAddNewRow}
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          disabled={isFetching}
          data-testid="add-button"
        >
          Add Specification
        </Button>
      </div>
      <div
        className="ag-theme-alpine header-white"
        data-testid="roles-management-table"
      >
        <AgGridReact
          rowData={_.cloneDeep(roleSpecifications)} //without clone deep props.data are not extensible with isEditActive or isNewRow
          gridOptions={createGridOptions(
            updateRoleSpecification,
            deleteRoleSpecification
          )}
          onGridReady={onGridReady}
          onGridSizeChanged={(event: GridSizeChangedEvent) =>
            event.api.sizeColumnsToFit()
          }
        />
      </div>
    </>
  );
};

export default RoleSpecificationManagement;
