import _ from "lodash";
import * as constants from "@src/constants";

export const cellClassRules = {
  "month-allocation-valid-changed-value": (params) => {
    const year = params.colDef.year
      ? params.colDef.year
      : parseInt(
          params.colDef.colId.substring(0, params.colDef.colId.indexOf("_"))
        );
    const month = params.colDef.month
      ? params.colDef.month
      : parseInt(
          params.colDef.colId.substring(
            params.colDef.colId.indexOf("_") + 1,
            params.colDef.colId.length
          )
        );

    if (params.node.initialData) {
      const yearAllocations = params.node.initialData.yearlyAllocations[year];
      const oldValue = yearAllocations ? yearAllocations[month] : undefined;
      const newValue = params.value ? parseInt(params.value) : 0;

      if (newValue === 0 && oldValue) {
        return true;
      } else if (
        newValue !== 0 &&
        newValue !== oldValue &&
        (newValue < 100 || newValue >= 0)
      ) {
        return true;
      }
    }
    return false;
  },
  "month-allocation-invalid-changed-value": (params) => {
    if (!params.node.rowPinned && !params.data.section) {
      const checkValue = () => {
        const newValue = Number(params.value);
        return isNaN(newValue) || newValue > 100 || newValue < 0;
      };

      if (params.node.selected) {
        if (_.isEmpty(params.data.yearlyAllocations)) {
          return true;
        } else {
          return checkValue();
        }
      } else {
        return checkValue();
      }
    } else {
      return false;
    }
  },
};

const addMonthsCols = (
  year,
  startMonth,
  endMonth,
  isSuggestWorkload,
  requestedAvailability
) => {
  const month = [];
  for (let m = startMonth; m < endMonth; m++) {
    let cellConfig = {
      headerName: constants.months[m],
      field: `${year}_${m + 1}`,
      colKey: `${year}_${m + 1}`,
      colId: `${year}_${m + 1}`,
      minWidth: 70,
      menuTabs: [],
      lockPosition: true,
      cellClassRules: cellClassRules,
      cellClass: "ag-grid-cell ag-grid-cell-centered",
      flex: 1,
      aggFunc: "sum",
      editable: (params) => !params.node.rowPinned,
      valueGetter: (params) => {
        if (params.node.rowPinned) {
          return params.context?.[`${year}_${m + 1}`];
        }
        const allocation =
          params.data.yearlyAllocations !== undefined &&
          params.data.yearlyAllocations[year] !== undefined &&
          params.data.yearlyAllocations[year][m + 1] !== undefined
            ? params.data.yearlyAllocations[year][m + 1]
            : 0;

        if (!allocation || allocation === "0" || allocation === "0%") {
          return 0;
        }

        return allocation;
      },
      valueFormatter: (params) => {
        if (params.value === 0) return "";
        const isNumber = !isNaN(parseInt(params.value));
        if (isNumber) {
          return params.node.rowPinned
            ? params.value / 100
            : `${params.value}%`;
        }
        return params.value ? params.value : "";
      },
    };

    if (isSuggestWorkload) {
      if (requestedAvailability[year] && requestedAvailability[year][m + 1]) {
        cellConfig.headerClass = function () {
          return "header-class";
        };
      } else {
        cellConfig.headerClass = function () {
          return "header-class faded-class";
        };
      }
    } else {
      cellConfig.headerClass = function () {
        return "header-class";
      };
    }

    month.push(cellConfig);
  }
  return month;
};

const getProjectAvailabilityMonths = (
  startYear,
  endYear,
  currentIteratedYear,
  startMonth,
  endMonth
) => {
  const currentYear = new Date().getFullYear();

  if (currentIteratedYear === currentYear && currentYear !== endYear) {
    return [startMonth, 12];
  } else if (currentYear === endYear) {
    return [startMonth, endMonth + 1];
  } else if (currentIteratedYear === endYear) {
    return [0, endMonth + 1];
  }
  return [0, 12];
};

export const getMonthlyAllocationCells = (
  startDate,
  endDate,
  isSuggestedWorkload,
  requestedAvailability
) => {
  const projectStartDate = new Date(startDate);
  const projectEndDate = new Date(endDate);

  const projectStartYear = projectStartDate.getFullYear();
  const projectEndYear = projectEndDate.getFullYear();
  const projectEndMonth = projectEndDate.getMonth();

  const monthlyAllocationCols = [];
  const date = new Date();
  for (let y = date.getFullYear(); y <= projectEndYear; y++) {
    const currentShownYear =
      date.getFullYear() > projectStartYear
        ? date.getFullYear()
        : projectStartYear;

    let monthRange = getProjectAvailabilityMonths(
      currentShownYear,
      projectEndYear,
      y,
      date.getMonth(),
      projectEndMonth
    );
    monthlyAllocationCols.push({
      headerName: `${y}`,
      menuTabs: [],
      lockPosition: true,
      field: "allocation",
      children: !isSuggestedWorkload
        ? addMonthsCols(y, monthRange[0], monthRange[1])
        : addMonthsCols(
            y,
            monthRange[0],
            monthRange[1],
            isSuggestedWorkload,
            requestedAvailability[0].yearlyAllocations
          ),
    });
  }
  return monthlyAllocationCols;
};
