import { ColDef, GridOptions } from "ag-grid-community";

const columns: ColDef[] = [
  {
    headerName: "Project Name",
    field: "name",
    cellClass: "text-cell",
    filter: "agSetColumnFilter",
    flex: 1,
    editable: false,
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: "Country",
    field: "country",
    cellClass: "text-cell",
    filter: "agSetColumnFilter",
    minWidth: 100,
    maxWidth: 300,
    flex: 1,
    editable: false,
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: "Project Manager",
    field: "projectManager",
    cellRenderer: "projectManagerRenderer",
    maxWidth: 300,
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: "Project Editor",
    field: "projectEditors",
    cellRenderer: "projectEditorRenderer",
    cellClass: "project-editor-cell ag-grid-cell no-border",
    autoHeight: true,
    minWidth: 400,
    flex: 1,
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: "Actions",
    field: "actions",
    cellRenderer: "actionsRenderer",
    cellClass: "actions-cell ag-grid-cell no-border",
    autoHeight: true,
    width: 400,
    wrapText: true,
    suppressSizeToFit: true,
    pinned: "right",
  },
];

export const projectOverviewGridOptions: GridOptions = {
  columnDefs: [...columns],
  rowHeight: 150,
  domLayout: "autoHeight",
  suppressMenuHide: true,
  suppressContextMenu: true,
  defaultColDef: {
    resizable: true,
    suppressMenu: true,
    suppressMovable: true,
  },
  pagination: true,
  paginationPageSize: 10,
};
