import { Project, ProjectPayload } from "@src/types";
import _ from "lodash";
import moment from "moment";

export const getProjectDto = (data: Project): ProjectPayload => {
  return {
    projectId: data.id,
    projectManager: data.projectManager.employeeId,
    editors: _.cloneDeep(data.projectEditors.map((e) => e.employeeId)),
    name: data.name,
    phase: data.projectPhase,
    countryId: data.countryId,
    startDate: moment(data.startDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
    endDate: moment(data.endDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
    stageGateDate: data.stageGateDate
      ? moment(data.stageGateDate, "YYYY-MM-DD").format("DD/MM/YYYY")
      : null,
    jointVenturePartner: data.jointVenturePartner,
    technologies: data.technologies,
    projectType: data.projectType,
  };
};
