import { useState } from "react";

import { AuthorizedContent } from "@src/components/authorized_content";
import { ROLES } from "@src/constants";
import * as constants from "@src/constants";
import { Layout } from "antd";

import "./app_layout.less";

import { HelpInfo } from "./components/help_info";
import { ImpersionateUser } from "./components/impersionate_user";
import { Logo } from "./components/logo";
import { Navigation } from "./components/navigation";
import { UserArea } from "./components/user_area";
import { useAuthentication } from "@src/auth/useAuthentication";

const { Content } = Layout;

const AppLayout = ({ children }) => {
  const [activeRoute, setActiveRoute] = useState(null);
  const { hasAccess, isAuthenticating, reasonPhrase, loggedInUser } =
    useAuthentication();

  if (isAuthenticating) return <h1>Loading...</h1>;

  if (!hasAccess) {
    return (
      <p data-testid="unauthorized-message">
        You don't have access privilege. Please contact support team or send a
        mail to <b>{constants.SUPPORT_ID}</b> with the reason below
        <div>
          <b>Reason:</b> ({reasonPhrase.toString().replace(",", " | ")})
        </div>
      </p>
    );
  }

  return (
    <Layout className="layout">
      <AuthorizedContent
        roles={[
          ROLES.PROJECT_LEAD,
          ROLES.ADMIN,
          ROLES.TEAM_LEAD,
          ROLES.PRIMARY_TEAM_LEAD,
          ROLES.DEPUTY,
          ROLES.MANAGER,
        ]}
      >
        <div className="main-header">
          <div className="header-navigation">
            <Logo />
            <Navigation
              loggedInUser={loggedInUser}
              setActiveRoute={setActiveRoute}
              activeRoute={activeRoute}
            />
            <div className="help-icon-user-section">
              <HelpInfo />
              <UserArea loggedInUser={loggedInUser} />
            </div>
          </div>
        </div>
        <div className="sub-navigation">
          <ImpersionateUser setActiveRoute={setActiveRoute} />
        </div>
      </AuthorizedContent>
      <Content>
        <div className="site-layout-content">{children}</div>
      </Content>
    </Layout>
  );
};

export default AppLayout;
