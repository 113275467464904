import { FillOperationParams } from "ag-grid-community";

export const gridOptions = (columnsDefs) => {
  return {
    defaultColDef: {
      flex: 1,
      editable: true,
      suppressMovable: true,
    },
    enableRangeSelection: true,
    enableFillHandle: true,
    rowSelection: "multiple",
    rowHeight: 60,
    columnDefs: [...columnsDefs],
    suppressMenuHide: true,
    suppressRowClickSelection: true,
    suppressCellFocus: false,
    suppressContextMenu: true,
    getRowNodeId: (data) => {
      return data.projectRoleRequestId;
    },
    fillOperation: (params: FillOperationParams) => {
      const isNonAllocationCell = isNaN(
        // @ts-ignore
        parseInt(params.column.colId.substring(0, 4))
      );
      if (isNonAllocationCell && params.direction === "left") {
        return params.currentCellValue;
      }
      return params.values[params.values.length - 1];
    },
  };
};
