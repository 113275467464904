import React from "react";
import { FilterDropdown } from "../../../../components/filter_dropdown";
import { FilterGroup } from "../../../../components/filter_group";

const FilterProjects = ({ gridApi, projects, countries, disabled }) => {
  let clearFilterProjects = null;
  let clearFilterCountries = null;

  const assignResetStateProjects = (filterResetFunction) => {
    clearFilterProjects = filterResetFunction;
  };

  const assignResetStateCountries = (filterResetFunction) => {
    clearFilterCountries = filterResetFunction;
  };

  const onChangeQuickFilter = (searchText) => {
    gridApi.current.setQuickFilter(searchText);
  };

  const onFilterChange = (values, field) => {
    gridApi.current.getFilterInstance(field).setModel({ values: values });
    gridApi.current.onFilterChanged();
  };

  const onClickResetFilter = () => {
    gridApi.current.setFilterModel(null);
    gridApi.current.setQuickFilter("");
    clearFilterProjects();
    clearFilterCountries();
  };

  return (
    <FilterGroup
      onFilterQuickSearch={onChangeQuickFilter}
      onReset={onClickResetFilter}
      disabled={disabled}
    >
      <FilterDropdown
        placeholderDropdown="Project Filter"
        placeholderSearch="Enter Project"
        treeData={projects}
        onFilterChange={(values) => onFilterChange(values, "name")}
        assignResetFunction={assignResetStateProjects}
        disabled={disabled}
      />
      <FilterDropdown
        placeholderDropdown="Country Filter"
        placeholderSearch="Enter Country"
        treeData={countries}
        onFilterChange={(values) => onFilterChange(values, "country")}
        assignResetFunction={assignResetStateCountries}
        disabled={disabled}
      />
    </FilterGroup>
  );
};

export default FilterProjects;
