import React, { useEffect, useState } from "react";

import { DeleteResourceModal } from "@src/components/modals/delete_resource_modal";
import { NoteModal } from "@src/components/note/note_modal";
import * as constants from "@src/constants";
import {
  useAcceptRequestMutation,
  useDeclineRequestMutation,
  useLazyGetRequestsRepliesQuery,
} from "@src/services/slices/projectsSlice";
import { switchNameAndSurname } from "@src/utils/helper";
import { Button, message, Modal } from "antd";

const RequestRepliesActionRenderer = ({ params, gridApi }) => {
  const [modalAttributes, setModalAttributes] = useState({
    visible: false,
    title: "",
    action: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState();

  const [refetchRequestsReplies] = useLazyGetRequestsRepliesQuery();
  const [declineRequest, { isSuccess: declinedRequestSuccess }] =
    useDeclineRequestMutation();
  const [acceptRequest, { isSuccess: acceptRequestSuccess }] =
    useAcceptRequestMutation();

  useEffect(() => {
    if (declinedRequestSuccess) {
      updateRowData("declined");
    }
  }, [declinedRequestSuccess]);

  useEffect(() => {
    if (acceptRequestSuccess) {
      updateRowData("accepted");
    }
  }, [acceptRequestSuccess]);

  const updateRowData = (variant) => {
    const row = params.api.getDisplayedRowAtIndex(params.rowIndex);
    params.api.redrawRows({ rowNodes: [row], force: true });

    let message = params.data.assignedTeamMember
      ? `${params.data.assignedTeamMember} is  successfully ${variant}`
      : `Request is successfully ${variant}`;

    getSuccessMessage(message);
    hideModal();
  };

  const getSuccessMessage = (successMessage) => {
    message.success({
      content: successMessage,
    });
  };

  const hideModal = () => {
    setModalAttributes({ visible: false, title: "", action: "" });
  };

  const showAcceptWarning = () => {
    let acceptWarning = (
      <p data-testid="acceptWarning">
        {params?.data?.assignedTeamMember
          ? "Are you sure you want to accept this person?"
          : "Are you sure you want to accept this request"}
      </p>
    );
    setModalAttributes({
      ...modalAttributes,
      visible: true,
      title: "Accept",
      content: acceptWarning,
      action: "Accept",
    });
  };

  const showDeclineWarning = () => {
    const modalMsg = {
      title: "Decline person",
      msg: params?.data?.assignedTeamMember ? (
        <span>
          Are you sure you want to decline{" "}
          <b>{switchNameAndSurname(params?.data?.assignedTeamMember)}</b>?
        </span>
      ) : (
        "Are you sure you want to decline this request"
      ),
      action: "Decline",
      leaveCommentFor: switchNameAndSurname(params?.data?.teamLead),
    };

    setModalMsg(modalMsg);
    setModalVisible(true);
  };

  const handleAction = async () => {
    if (modalAttributes.action === "Accept") {
      await acceptRequest(params.data.projectRoleRequestId);
    } else if (modalMsg.action === "Decline") {
      await declineRequest(params.data.projectRoleRequestId);
    }
  };

  const refreshAfterDelete = () => {
    gridApi.updateRowData({ remove: [params.data] });
    refetchRequestsReplies(params.data.projectDTO.id);
  };

  return (params.data.status === constants.REQUEST_STATUS.PROPOSED ||
    params.data.status === constants.REQUEST_STATUS.DECLINED) &&
    !params.data.isOutdated ? (
    <div className="accept-decline">
      <Button onClick={showDeclineWarning}>Decline</Button>
      <Button className="action" type="primary" onClick={showAcceptWarning}>
        Accept
      </Button>

      <NoteModal
        action={handleAction}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        requestId={params.data.parentRequestId}
        modalMsg={modalMsg}
        isProjectLead={true}
      />

      <Modal
        width={"30%"}
        open={modalAttributes.visible}
        centered
        closable={false}
        bodyStyle={{ width: "100%" }}
        title={modalAttributes.title}
        footer={[
          <Button data-testid="cancel" key="back" onClick={hideModal}>
            Cancel
          </Button>,
          <Button
            type={"primary"}
            data-testid="action"
            key="action"
            onClick={() => {
              handleAction(params);
            }}
          >
            {modalAttributes.action}
          </Button>,
        ]}
      >
        <div data-testid="modalMsg">{modalAttributes.content}</div>
      </Modal>
    </div>
  ) : (
    <div>
      {params.data.isOutdated && (
        <Button
          type="primary"
          danger
          onClick={() => setDeleteModalVisible(true)}
        >
          Delete
        </Button>
      )}
      {deleteModalVisible && (
        <DeleteResourceModal
          setModalDeleteVisible={setDeleteModalVisible}
          modalDeleteVisible={deleteModalVisible}
          requests={[params.data]}
          action={refreshAfterDelete}
          projectId={params.data.projectDTO.id}
        />
      )}
    </div>
  );
};

export default RequestRepliesActionRenderer;
