import React, { useEffect } from "react";

import "./tabs_staffing_requests.less";
import checkIcon from "@src/assets/icons/union.svg";
import warningIcon from "@src/assets/icons/warning.svg";
import FilterTabs from "@src/features/table_filtering/components/filter_tabs/filter_tabs";
import { setCurrentTabConfig } from "@src/services/requestOverviewSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { TabConfig } from "@src/types";

const tabsConfig: TabConfig[] = [
  {
    key: "required-action",
    label: "Required Action",
    defaultHiddenColumns: [
      "Request ID",
      "Country",
      "Region",
      "Project Name",
      "Project Phase",
      "Country",
      "Region",
      "Tag",
      "Required Action",
      "Technology",
    ],
    icon: <img src={warningIcon} alt={"warning icon"} />,
  },
  {
    key: "all",
    label: "All",
    defaultHiddenColumns: [
      "Request ID",
      "Tag",
      "Technology",
      "Required Action",
    ],
    icon: <img src={checkIcon} alt={"check icon"} />,
  },
];

interface TabsStaffingRequestProps {
  disabled?: boolean;
}

const TabsStaffingRequests: React.FC<TabsStaffingRequestProps> = ({
  disabled = false,
}) => {
  const dispatch = useAppDispatch();

  const {
    requestOverviewTableState: { currentTabConfig },
  } = useAppSelector((state: RootState) => state.requestOverviewSlice);

  useEffect(() => {
    if (!currentTabConfig) {
      applyTabConfigOnChange(tabsConfig[0].key);
    }
  }, [currentTabConfig]);

  function applyTabConfigOnChange(key: string) {
    const tabConfig = tabsConfig.find((tab) => tab.key === key);
    dispatch(
      setCurrentTabConfig({
        ...tabConfig,
        icon: undefined, // reset icon to undefined to avoid redux issues
      })
    );
  }

  return currentTabConfig ? (
    <FilterTabs
      disabled={disabled}
      items={tabsConfig}
      defaultActiveConfig={currentTabConfig}
      onChange={applyTabConfigOnChange}
    />
  ) : null;
};

export default TabsStaffingRequests;
