import React, { useEffect, useState } from "react";

import "./update_details_modal.less";
import { TooltipRenderer } from "@src/custom_renderer";
import { AgGridReact } from "ag-grid-react";
import { Modal } from "antd";
import _ from "lodash";
import moment from "moment";

export default function UpdateDetailsModal({
  setModalVisible,
  modalVisible,
  openedDetailsSapDTO,
}) {
  const [rowData, setRowData] = useState([]);
  const gridApi = React.useRef(null);

  const columnDefs = [
    { field: "attributeName", width: 205 },
    { field: "oldValue", width: 250, cellRenderer: "valueRenderer" },
    { field: "newValue", width: 250, cellRenderer: "valueRenderer" },
  ];

  const frameworkComponents = {
    valueRenderer: (props) => {
      if (
        props.value &&
        (props.data.attributeName === "position start date" ||
          props.data.attributeName === "position end date")
      ) {
        return TooltipRenderer(moment(props.value).format("YYYY-MM-DD"));
      }

      return TooltipRenderer(props.value);
    },
  };

  const gridOptions = {
    suppressContextMenu: true,
    suppressMenuHide: true,
    suppressRowClickSelection: true,
    enableCellChangeFlash: true,
    rowHeight: 60,
    domLayout: "autoHeight",
    defaultColDef: { resizable: true, suppressMenu: true },
  };

  useEffect(() => {
    setRowData(_.cloneDeep(openedDetailsSapDTO.data.oldValues));
  }, [openedDetailsSapDTO]);

  return (
    <Modal
      width="755px"
      open={modalVisible}
      centered
      title="Updated data overview"
      closable={false}
      maskClosable={false}
      bodyStyle={{ width: "100%" }}
      onCancel={() => setModalVisible(false)}
      okButtonProps={{ style: { display: "none" } }}
    >
      <div className="ag-theme-alpine sap-update-details-table header-white">
        <AgGridReact
          rowData={rowData}
          components={frameworkComponents}
          gridOptions={gridOptions}
          columnDefs={columnDefs}
          onGridReady={(params) => (gridApi.current = params.api)}
        />
      </div>
    </Modal>
  );
}
