import React, { useState, useRef, useEffect, useMemo } from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import { PlusOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import { DownloadResourcePlanByRegion } from "@src/components/download_resource_plan_by_region";
import { SelectionRenderer } from "@src/custom_renderer";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import { useGetProjectLeadsQuery } from "@src/services/slices/employeesSlice";
import {
  useGetAllProjectsQuery,
  useUpdateProjectMutation,
} from "@src/services/slices/projectsSlice";
import { Employee, Project } from "@src/types";
import {
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Button, message } from "antd";
import _ from "lodash";

import { ActionsRenderer } from "../../custom_renderer";
import "./admin_project_overview.less";
import { projectOverviewGridOptions } from "../../utils/admin_project_overview_grid_options";
import { getProjectDto } from "../../utils/admin_project_overview_utils";
import { FilterProjects } from "../filter_projects";

const AdminProjectOverview = () => {
  const gridApi = useRef<GridApi>(null);
  const [projects, setProjects] = useState<Project[]>(undefined); //needs to be undefined so that ag grid knows that a loading overlay should be shown
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [currentProps, setCurrentProps] = useState<ICellRendererParams>(null);

  const [updateProject, { isSuccess: updateProjectSuccess }] =
    useUpdateProjectMutation();

  const {
    data: allProjects,
    isSuccess: allProjectsSuccess,
    isLoading,
  } = useGetAllProjectsQuery(null, {
    selectFromResult: ({ data, isSuccess, isLoading }) => {
      const filteredData = data
        ?.filter((project: Project) => project.name !== "Time Blocker")
        .map((project: Project) => ({
          ...project,
          isEditActive: false,
        }));

      return { data: filteredData, isSuccess, isLoading };
    },
  });
  const { data: projectLeads } = useGetProjectLeadsQuery();

  const context = useMemo(() => {
    return {
      projectLeads: projectLeads,
    };
  }, [projectLeads]);

  useEffect(() => {
    if (allProjectsSuccess && allProjects) {
      const projectsWithIsEditActiveProp: Project[] = allProjects.map(
        (project: Project) => ({
          ...project,
          isEditActive: false,
        })
      );

      projectOverviewGridOptions.context.projects =
        projectsWithIsEditActiveProp;

      setProjects(_.cloneDeep(projectsWithIsEditActiveProp));
    }
  }, [allProjectsSuccess]);

  useEffect(() => {
    if (updateProjectSuccess) {
      toggleEdit(false, currentProps);
      updateProjects(false, currentProps);
      message.success("Successfully changed project.", 5);
    }
  }, [updateProjectSuccess]);

  useEffect(() => {
    if (gridApi.current && !isLoading) {
      gridApi.current.hideOverlay();
    }

    setIsTableLoading(!allProjectsSuccess);
  }, [isLoading, gridApi.current]);

  const onAddProject = () => {
    navigate("/projects/create/-1");
  };

  const onEdit = (props: ICellRendererParams) => {
    toggleEdit(true, props);
  };

  const onCancel = (props: ICellRendererParams) => {
    toggleEdit(false, props);
    // reset fields
    const project: Project = props.context.projects.find(
      (project: Project) => project.id === props.data.id
    );
    props.node.setDataValue("projectManager", project.projectManager);
    props.node.setDataValue("projectEditors", project.projectEditors);
  };

  const onSave = async (props: ICellRendererParams) => {
    setCurrentProps(props);
    const projectDto = getProjectDto(props.data);
    await updateProject(projectDto);
  };

  const onDelete = async (props: ICellRendererParams) => {
    setCurrentProps(props);
    updateProjects(true, props);
    gridApi.current.applyTransaction({ remove: [props.data] });
  };

  const onImport = (props: ICellRendererParams) => {
    navigate(`/projectAssignments/${props.data.id}/import`);
  };

  const toggleEdit = (value: boolean, props: ICellRendererParams) => {
    const index = props.context.projects.findIndex(
      (project: Project) => project.id === props.data.id
    );
    props.context.projects[index].isEditActive = value;
    props.data.isEditActive = value;
    gridApi.current.redrawRows({ rowNodes: [props.node] });
  };

  const updateProjects = (isDelete: boolean, props: ICellRendererParams) => {
    const index = props.context.projects.findIndex(
      (project: Project) => project.id === props.data.id
    );
    if (isDelete) {
      props.context.projects.splice(index, 1);
    } else {
      props.context.projects[index].projectManager = props.data.projectManager;
      props.context.projects[index].projectEditors = props.data.projectEditors;
    }
  };

  const updateProjectManager = (
    selectedEmployeeId: number,
    props: ICellRendererParams
  ) => {
    const projectManager = props.context.projectLeads.find(
      //selectedEmployeeId is actually string, but js is converting it to a number
      // this must be fixed when selection_renderer is changed to typeScript
      //and using to string function on the employeeId causes some issues
      (employee: Employee) => employee.employeeId === selectedEmployeeId
    );
    props.node.setDataValue("projectManager", projectManager);
  };

  const updateProjectEditor = (
    selectedEmployeeIds: number[],
    props: ICellRendererParams
  ) => {
    const projectEditors: string[] = [];
    props.context.projectLeads.forEach((employee: Employee) => {
      //selectedEmployeeIds is actually string array, but js is converting it to a number array
      //this must be fixed when selection_renderer is changed to typeScript
      //and using to string function on the employeeId causes some issues
      if (selectedEmployeeIds.includes(employee.employeeId)) {
        projectEditors.push(_.cloneDeep(employee));
      }
    });
    props.node.setDataValue("projectEditors", projectEditors);
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    gridApi.current.sizeColumnsToFit();
  };

  const frameworkComponents = function () {
    return {
      projectManagerRenderer: (props: ICellRendererParams) => {
        return (
          <SelectionRenderer
            props={props}
            dataToExtractOptions={props?.context.projectLeads}
            propertyForOptionValues="employeeId"
            propertyForOptionLabels="fullName"
            defaultValue={props?.data.projectManager.fullName}
            style={{ width: "100%" }}
            placeholder="Select Project Manager"
            onChangeCallback={updateProjectManager}
            disabled={!props?.data.isEditActive}
          />
        );
      },
      projectEditorRenderer: (props: ICellRendererParams) => {
        return (
          <SelectionRenderer
            props={props}
            dataToExtractOptions={props?.context?.projectLeads}
            propertyForOptionValues="employeeId"
            propertyForOptionLabels="fullName"
            defaultValue={props?.data.projectEditors?.map(
              (editor: Employee) => editor.employeeId
            )}
            style={{ width: "100%" }}
            placeholder="Select Project Editors"
            isMultiple
            onChangeCallback={updateProjectEditor}
            disabled={!props?.data.isEditActive}
          />
        );
      },
      actionsRenderer: (props: ICellRendererParams) => (
        <ActionsRenderer
          props={props}
          onEdit={onEdit}
          onCancel={onCancel}
          onSave={onSave}
          onDelete={onDelete}
          onImport={onImport}
        />
      ),
    };
  };

  return (
    <div className="admin-project-overview">
      <div className="filter-buttons-section">
        <Button
          className="add-project-button"
          size="large"
          type="primary"
          onClick={onAddProject}
          disabled={isTableLoading}
          icon={<PlusOutlined />}
          data-testid="add-project-button"
        >
          Add Project
        </Button>
        <div className="filter-download-section">
          <FilterProjects
            gridApi={gridApi}
            projects={mapToTreeData(projects, "name")}
            countries={mapToTreeData(projects, "country")}
            disabled={isTableLoading}
          />
          <DownloadResourcePlanByRegion disabled={isTableLoading} />
        </div>
      </div>

      <div className="ag-theme-alpine header-white">
        <AgGridReact
          onGridReady={onGridReady}
          context={context}
          rowData={projects}
          gridOptions={projectOverviewGridOptions}
          components={frameworkComponents()}
        />
      </div>
    </div>
  );
};

export default AdminProjectOverview;
