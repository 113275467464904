import React from "react";

import "./status_renderer.less";
import profile from "@src/assets/icons/profile.svg";

interface StatusRendererProps {
  status: string;
  assignedTeamMember?: string;
}

const StatusRenderer: React.FC<StatusRendererProps> = ({
  status,
  assignedTeamMember,
}) => {
  function formatStatusClassName(status: string) {
    return status?.toLowerCase()?.replace(" ", "-");
  }

  return (
    <div className="status-renderer">
      <div className="status-renderer-text">
        <div
          data-testid="status-renderer-icon"
          className={
            "status-renderer-icon " +
            `status-renderer-icon-${formatStatusClassName(status)}`
          }
        />
        <div>{status}</div>
      </div>
      {assignedTeamMember && (
        <div className="status-renderer-subtext">
          <img
            src={profile}
            alt="project lead"
            className="status-renderer-profile-icon"
          />
          <span>{assignedTeamMember}</span>
        </div>
      )}
    </div>
  );
};

export default StatusRenderer;
