import React from "react";

import { Card } from "antd";
import "./simple_custom_card.less";

export interface SimpleCustomCardProps {
  data: number;
  title: string;
  className?: string;
  type?: "require-action" | "decline" | "";
  isLoading?: boolean;
}

const SimpleCustomCard: React.FC<SimpleCustomCardProps> = ({
  data,
  title,
  className = "",
  type = "",
  isLoading = false,
}) => {
  const classNameModifier = type ? `simple-custom-card--${type}` : "";

  return (
    <Card
      title={title}
      className={`simple-custom-card ${classNameModifier} ${className}`}
      data-testid="simple-custom-card"
      loading={isLoading}
    >
      <label
        className="simple-custom-card__label"
        data-testid="simple-custom-card__label"
      >
        {data}
      </label>
    </Card>
  );
};

export default SimpleCustomCard;
