import { getErrorMessage } from "@src/utils/helper";

function setErrors(params, field) {
  if (!params.node.error) {
    params.node.error = {};
  }
  params.node.error[field || params.colDef.field] = true;
}

export const isNonZeroAllocations = (allocations) => {
  let result = false;
  if (Object.values(allocations).length === 0) {
    result = false;
  }
  Object.values(allocations).forEach((eachYear) => {
    if (Object.values(eachYear).length > 0) {
      Object.values(eachYear).forEach((eachMonthValue) => {
        if (Number(eachMonthValue) && parseInt(eachMonthValue) > 0) {
          result = true;
        }
      });
    }
  });
  return result;
};

export const validateMonthlyAllocation = (params, year, month) => {
  let yearlyAllocations = { ...params.node.data.yearlyAllocations };
  let updatedValue = params.value;

  if (!yearlyAllocations[year]) {
    yearlyAllocations[year] = {};
  }
  if (Number.isInteger(parseInt(params.value))) {
    yearlyAllocations[year][month] = parseInt(params.value);
  } else {
    delete yearlyAllocations[year][month];
  }

  let nonZeroAllocation = isNonZeroAllocations(yearlyAllocations);

  let isValid = true;

  if (
    params.value &&
    params.value !== "" &&
    isNaN(params.value) &&
    params.value.match(/[^0-9\\-]/g)
  ) {
    isValid = false;
    setErrors(params);

    getErrorMessage(
      "You have entered invalid values. Correct examples: 0%, 79%, 100%"
    );
  } else if (parseInt(params.value) > 100 || parseInt(params.value) < 0) {
    isValid = false;
    updatedValue = parseInt(params.value);
    setErrors(params);

    getErrorMessage("Invalid values: Please insert values between 0% and 100%");
  } else if (params.value === "") {
    updatedValue = "";
  } else {
    updatedValue = parseInt(params.value);

    if (params.node.error) {
      delete params.node.error[`${year}_${month}`];
    }
  }

  if (!nonZeroAllocation) {
    if (params.node.selected) {
      setErrors(params, "yearlyAllocations");
      params.api.redrawRows();
      getErrorMessage("Missing Fields: please enter a allocation  ");
    }
  } else if (params.node.error) {
    delete params.node.error.yearlyAllocations;
  }

  return {
    isValid,
    updatedValue,
  };
};

export const updateFTEData = (api, setContext) => {
  if (api.getRenderedNodes().length === 0) return;

  const node = api.getRenderedNodes()[0];
  const pinnedRow = api.getPinnedTopRow(0);
  setContext(node?.parent?.aggData);
  api.refreshCells({ rowNodes: [pinnedRow] });
};
