import React from "react";

import { PROJECT_PHASE, REQUEST_STATUS, TECHNOLOGY } from "@src/constants";
import PhaseRenderer from "@src/features/staffing_requests/custom_renderer/phase_renderer/phase_renderer";
import StatusRenderer from "@src/features/staffing_requests/custom_renderer/status_renderer/status_renderer";
import DateRangeFilter from "@src/features/table_filtering/components/date_range_filter/date_range_filter";
import SelectionFilter from "@src/features/table_filtering/components/selection_filter/selection_filter";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import "./filter_staffing_requests.less";
import {
  setDateFilter,
  setCustomTableFilter,
} from "@src/services/requestOverviewSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { REQUEST_OVERVIEW_FILTER_TYPES } from "@src/types";
import { TreeNode } from "@src/types/antd_types";
import dayjs, { Dayjs } from "dayjs";

interface FilterStaffingRequestsProps {
  countries: TreeNode[];
  regions: TreeNode[];
  projectName: TreeNode[];
  requestedRole: TreeNode[];
  disabled: boolean;
}

const FilterStaffingRequests: React.FC<FilterStaffingRequestsProps> = ({
  countries,
  regions,
  projectName,
  requestedRole,
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const {
    requestOverviewTableState: { dateFilter, customTableFilters },
  } = useAppSelector((state: RootState) => state.requestOverviewSlice);

  /**
   * Updates the custom table filter by the given type.
   *
   * @param selectedElements {string[]}         selected elements to be filtered (eg. countries, regions, technologies)
   * @param fieldTypeName {string}              field type name of filter
   */
  function updateCustomTableFilterByType(
    selectedElements: string[],
    fieldTypeName: string
  ): void {
    dispatch(
      setCustomTableFilter({
        ...customTableFilters,
        [fieldTypeName]: selectedElements,
      })
    );
  }

  /**
   * Handles the change of date filters by converting Dayjs objects to ISO strings
   * and dispatching the updated date filter values to the Redux store.
   *
   * @param {Dayjs[]} values - Array of Dayjs objects representing the selected date filters.
   * @returns {void}
   */
  function onDateFilterChange(values: Dayjs[]): void {
    const isoStringValues = values?.map((date) => date.toISOString());
    dispatch(setDateFilter(isoStringValues));
  }

  /**
   * Parses an array of ISO date strings into an array of Dayjs objects.
   *
   * @param {string[]} dateFilter - Array of ISO date strings to be parsed.
   * @returns {Dayjs[]} - Array of Dayjs objects parsed from the input strings.
   */
  function parseDateFilter(dateFilter: string[]): Dayjs[] {
    if (!dateFilter || dateFilter.length === 0) {
      return [];
    }
    return dateFilter.map((date) => dayjs(date));
  }

  return (
    <>
      <DateRangeFilter
        title="Time Range"
        dateFilter={parseDateFilter(dateFilter)}
        disabledFrom={disabled}
        disabledTo={disabled}
        onRangePickerChange={onDateFilterChange}
      />
      <SelectionFilter
        title="Requested Role"
        treeData={requestedRole}
        disabled={disabled}
        defaultKeys={customTableFilters.country}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            REQUEST_OVERVIEW_FILTER_TYPES.REQUESTED_ROLE
          );
        }}
      />
      <SelectionFilter
        title="Phase"
        treeData={mapToTreeData(Object.values(PROJECT_PHASE))}
        customRenderer={(node: TreeNode) => (
          <PhaseRenderer status={node.title.toString()} />
        )}
        disabled={disabled}
        defaultKeys={customTableFilters.country}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            REQUEST_OVERVIEW_FILTER_TYPES.PROJECT_PHASE
          );
        }}
      />
      <SelectionFilter
        title="Project Name"
        treeData={projectName}
        disabled={disabled}
        defaultKeys={customTableFilters.country}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            REQUEST_OVERVIEW_FILTER_TYPES.PROJECT_NAME
          );
        }}
      />
      <SelectionFilter
        title="Country Filter"
        treeData={countries}
        disabled={disabled}
        defaultKeys={customTableFilters.country}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            REQUEST_OVERVIEW_FILTER_TYPES.COUNTRY
          );
        }}
      />
      <SelectionFilter
        title="Region Filter"
        treeData={regions}
        disabled={disabled}
        defaultKeys={customTableFilters.region}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            REQUEST_OVERVIEW_FILTER_TYPES.REGION
          );
        }}
      />
      <SelectionFilter
        title="Technology Filter"
        treeData={mapToTreeData(Object.values(TECHNOLOGY))}
        disabled={disabled}
        defaultKeys={customTableFilters.technologies}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            REQUEST_OVERVIEW_FILTER_TYPES.TECHNOLOGIES
          );
        }}
      />

      <SelectionFilter
        title="Status"
        treeData={mapToTreeData([
          REQUEST_STATUS.REQUESTED,
          REQUEST_STATUS.IN_PROGRESS,
          REQUEST_STATUS.PROPOSED,
          REQUEST_STATUS.IN_RECRUITMENT,
          REQUEST_STATUS.DECLINED,
          REQUEST_STATUS.CONFIRMED,
        ])}
        disabled={disabled}
        defaultKeys={customTableFilters.status}
        customRenderer={(node: TreeNode) => (
          <StatusRenderer status={node.title.toString()} />
        )}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            REQUEST_OVERVIEW_FILTER_TYPES.STATUS
          );
        }}
      />
    </>
  );
};

export default FilterStaffingRequests;
