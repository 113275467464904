import React from "react";

import { ROLES } from "@src/constants";
import { SelectionRenderer } from "@src/custom_renderer";
import { EmployeeDataComponent } from "@src/pages/admin/employee_data_page/employee_data_page";
import {
  ApplicationRole,
  Department,
  Employee,
  Page,
  StandardRole,
} from "@src/types";
import {
  CellClassParams,
  ColDef,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  SetFilterValuesFuncParams,
} from "ag-grid-community";
import { Button } from "antd";
import _ from "lodash";

import {
  updateApplicationRole,
  updateDepartment,
  updateStandardRole,
} from "./employee_table_utils";

export const changeDetectionCellClassRule = {
  "value-changed": (params: CellClassParams): boolean => {
    const oldValues = params.context?.employees.find(
      (employee: Employee) => employee.employeeId === params.data.employeeId
    );

    if (params.colDef.field === "departmentId" && oldValues) {
      return oldValues.departmentId !== params.data.departmentId;
    } else if (params.colDef.field === "genericRoleId" && oldValues) {
      return oldValues.genericRoleId !== params.data.genericRoleId;
    } else if (params.colDef.field === "teamLeadId" && oldValues) {
      return oldValues.teamLeadId !== params.data.teamLeadId;
    } else if (params.colDef.field === "applicationRoles" && oldValues) {
      return (
        _.xor(oldValues.applicationRoles, params.data.applicationRoles).length >
        0
      );
    }
    return false;
  },
};

export const columns: ColDef[] = [
  {
    headerName: "Full name",
    field: "fullName",
    minWidth: 260,
    filter: "agTextColumnFilter",
    resizable: true,
    floatingFilter: true,
    suppressMenu: true,
    sortable: true,
    unSortIcon: true,
    filterParams: {
      newRowsAction: "keep",
      filterOptions: ["contains"],
      maxNumConditions: 1,
    },
  },
  {
    headerName: "UI Number",
    field: "uiNumber",
    width: 220,
    minWidth: 220,
    filter: "agTextColumnFilter",
    resizable: true,
    floatingFilter: true,
    suppressMenu: true,
    sortable: true,
    unSortIcon: true,
    filterParams: {
      newRowsAction: "keep",
      maxNumConditions: 1,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: "Position ID",
    field: "deskId",
    width: 220,
    minWidth: 220,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressMenu: true,
    sortable: true,
    unSortIcon: true,
    filterParams: {
      newRowsAction: "keep",
      maxNumConditions: 1,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: "Org-Code",
    field: "departmentId",
    width: 155,
    minWidth: 155,
    filter: "agSetColumnFilter",
    cellRenderer: "showDepartmentRenderer",
    resizable: true,
    suppressMenu: true,
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    cellClassRules: changeDetectionCellClassRule,
    filterParams: {
      newRowsAction: "keep",
      values: async (params: SetFilterValuesFuncParams) => {
        const departments: Department[] = await params.context
          .getDepartments()
          .unwrap();
        params.success(
          departments.map((department: Department) => department.orgUnit)
        );
      },
    },
  },
  {
    headerName: "Standard role",
    field: "genericRoleId",
    width: 300,
    minWidth: 300,
    filter: "agSetColumnFilter",
    resizable: true,
    floatingFilter: true,
    sortable: true,
    unSortIcon: true,
    cellRenderer: "showRoleRenderer",
    cellClassRules: changeDetectionCellClassRule,
    filterParams: {
      newRowsAction: "keep",
      values: async (params: SetFilterValuesFuncParams) => {
        const standardRoles: StandardRole[] = await params.context
          .getStandardRoles()
          .unwrap();
        params.success(
          standardRoles?.map((role: StandardRole) => role.standardRoleName)
        );
      },
    },
  },
  {
    headerName: "Account role",
    field: "applicationRoles",
    width: 335,
    minWidth: 335,
    filter: "agSetColumnFilter",
    cellClassRules: changeDetectionCellClassRule,
    cellRenderer: "showApplicationRoleRenderer",
    resizable: true,
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    filterParams: {
      newRowsAction: "keep",
      values: Object.values(ROLES),
    },
  },
  {
    headerName: "Team lead",
    field: "teamLeadFullName",
    width: 250,
    minWidth: 250,
    filter: "agSetColumnFilter",
    resizable: true,
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    filterParams: {
      newRowsAction: "keep",
      values: async (params: SetFilterValuesFuncParams) => {
        const teamLeads: Page<Employee> = await params.context
          .searchEmployees({
            filterParams: { roles: [ROLES.TEAM_LEAD, ROLES.PROJECT_LEAD] },
          })
          .unwrap();
        params.success(
          teamLeads?.content?.map((teamLead: Employee) => teamLead.fullName)
        );
      },
    },
  },
  {
    width: 50,
    pinned: "right",
    checkboxSelection: true,
    menuTabs: [],
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    pinned: "right",
    width: 90,
    minWidth: 90,
    menuTabs: [],
    cellRenderer: "showMoreInformationRenderer",
    filterParams: {
      newRowsAction: "keep",
    },
  },
];

export const getComponents = function (
  setShowMoreEmployeeData: (employeeData: Employee) => void,
  setShowMore: (showMore: boolean) => void
): EmployeeDataComponent {
  return {
    showMoreInformationRenderer: (
      params: ICellRendererParams
    ): React.ReactElement => {
      return (
        <Button
          type="link"
          onClick={() => {
            setShowMoreEmployeeData(params.data);
            setShowMore(true);
          }}
        >
          more
        </Button>
      );
    },
    showApplicationRoleRenderer: (
      params: ICellRendererParams
    ): React.ReactElement => {
      return (
        <SelectionRenderer
          props={params}
          dataToExtractOptions={params.context?.applicationRoles}
          propertyForOptionValues="applicationRoleId"
          propertyForOptionLabels="applicationRoleName"
          defaultValue={params.context?.applicationRoles
            .filter((role: ApplicationRole) =>
              params.data.applicationRoles.includes(role.applicationRoleName)
            )
            ?.map((role: ApplicationRole) => role.applicationRoleId)}
          style={{ width: "280px" }}
          placeholder="Select Application Role"
          isMultiple
          onChangeCallback={updateApplicationRole}
        />
      );
    },
    showDepartmentRenderer: (
      params: ICellRendererParams
    ): React.ReactElement => {
      return (
        <SelectionRenderer
          props={params}
          dataToExtractOptions={params.context?.orgCodes}
          propertyForOptionValues="departmentId"
          propertyForOptionLabels="orgUnit"
          defaultValue={params.data.departmentCode}
          style={{ minWidth: "120px" }}
          placeholder="Select Org-Code"
          onChangeCallback={updateDepartment}
        />
      );
    },
    showRoleRenderer: (params: ICellRendererParams): React.ReactElement => {
      return (
        <SelectionRenderer
          props={params}
          dataToExtractOptions={params.context?.standardRoles}
          propertyForOptionValues="standardRoleId"
          propertyForOptionLabels="standardRoleName"
          defaultValue={
            params.context.standardRoles?.find(
              (role: StandardRole) =>
                role.standardRoleId === params.data?.genericRoleId
            )?.standardRoleName || params.data?.genericRole
          }
          style={{ width: "280px" }}
          placeholder="Select Standard Role"
          onChangeCallback={updateStandardRole}
        />
      );
    },
  };
};

export const getGridOptions = (): GridOptions => {
  return {
    columnDefs: [...columns],
    defaultColDef: {
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
    },
    suppressContextMenu: true,
    rowSelection: "multiple",
    rowHeight: 90,
    domLayout: "autoHeight",
    suppressMenuHide: true,
    suppressRowClickSelection: true,
    pagination: true,
    paginationPageSize: 10,
    getRowId: function (params: GetRowIdParams) {
      return params.data.employeeId;
    },
  };
};
