import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  CellStyle,
  ColDef,
  GridOptions,
  ICellRendererParams,
} from "ag-grid-community";

import { RoleTypes } from "./role_assignment_constants";
import { RoleAssignmentActionsRenderer } from "../custom_renderer/role_management_actions_renderer";

const cellWrapText: CellStyle = {
  lineHeight: "20px",
  whiteSpace: "normal",
};

interface RoleAssignmentContext {
  roleType: RoleTypes;
}

export interface ActionsRendererGridComponents {
  roleManagementActionsRenderer: (
    params: ICellRendererParams
  ) => React.ReactElement;
}

const actionColumnDef: ColDef = {
  headerName: "Actions",
  field: "actions",
  cellRenderer: "roleManagementActionsRenderer",
  pinned: "right",
  suppressSizeToFit: true,
  width: 150,
  sortable: false,
  resizable: false,
};

/**
 * builds the actions renderer. it's rendering the buttons in the table
 *
 * @param updateRoleRtkFunction function to reach the update endpoint. Is called when modal is saved
 * @param deleteRoleRtkFunction function to reach the delete endpoint. Is called when delete button is clicked and detele modal is confirmed
 * @param onSplit onClick function for the split button if given
 * @returns
 */
function getActionsRenderer(
  updateRoleRtkFunction: MutationTrigger<any>,
  deleteRoleRtkFunction: MutationTrigger<any>,
  onSplit?: (params: ICellRendererParams) => void
): ActionsRendererGridComponents {
  return {
    roleManagementActionsRenderer: (props: ICellRendererParams) => {
      return (
        <RoleAssignmentActionsRenderer
          params={props}
          updateRoleRtkFunction={updateRoleRtkFunction}
          deleteRoleRtkFunction={deleteRoleRtkFunction}
          onSplitCallback={onSplit}
        />
      );
    },
  };
}

/**
 * sets the context for the aggrid table
 *
 * @param roleType role type regarding the table
 * @returns the context
 */
function getContext(roleType: RoleTypes): RoleAssignmentContext {
  return {
    roleType: roleType,
  };
}

export function getCommonGridOptions(
  roleType: RoleTypes,
  columns: ColDef[],
  updateRoleRtkFunction: MutationTrigger<any>,
  deleteRoleRtkFunction: MutationTrigger<any>,
  onSplit?: (params: ICellRendererParams) => void
): GridOptions {
  return {
    columnDefs: [...columns, actionColumnDef],
    rowHeight: 50,
    domLayout: "autoHeight",
    suppressMenuHide: true,
    suppressContextMenu: true,
    pagination: true,
    paginationPageSize: 10,
    components: getActionsRenderer(
      updateRoleRtkFunction,
      deleteRoleRtkFunction,
      onSplit
    ),
    context: getContext(roleType),
    defaultColDef: {
      editable: false,
      sortable: true,
      unSortIcon: true,
      suppressMenu: true,
      suppressMovable: true,
      cellStyle: cellWrapText,
      resizable: true,
    },
  };
}
