import React, { useState } from "react";

import { DeleteProjectConfirmationModal } from "@src/components/modals/delete_project_confirmation_modal";
import { Button } from "antd";

const ActionsRenderer = ({
  props,
  onEdit,
  onCancel,
  onSave,
  onDelete,
  onImport,
}) => {
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

  return (
    <>
      <div className="actions-buttons">
        <Button
          disabled={props.data.isEditActive}
          type="primary"
          onClick={() => onEdit(props)}
          data-testid="edit-button"
        >
          Edit
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          type="primary"
          danger
          onClick={() => setShowDeleteProjectModal(true)}
          data-testid="delete-button"
        >
          Delete Project
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          onClick={() => onCancel(props)}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          type="primary"
          onClick={() => onSave(props)}
          data-testid="save-button"
        >
          Save
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          type="primary"
          onClick={() => onImport(props)}
          data-testid="import-button"
        >
          Import Resource Plan
        </Button>
      </div>

      {showDeleteProjectModal && (
        <DeleteProjectConfirmationModal
          setModalVisible={setShowDeleteProjectModal}
          modalVisible={showDeleteProjectModal}
          projectId={props.data.id}
          onDeleteCallback={() => onDelete(props)}
        />
      )}
    </>
  );
};

export default ActionsRenderer;
