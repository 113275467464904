import React, { useState } from "react";

import "./dashboard_page.less";
import {
  Headline,
  SimpleCustomCard,
  FilterSelection,
  FilterCollapse,
} from "@src/components";
import {
  RequestCountryStatisticsChart,
  RequestRepliedChart,
  RequestStatusStatisticsChart,
  TotalFteChart,
} from "@src/features/dashboard/components";
import {
  getTimeRangeOptions,
  mapToActiveFilter,
  mapToFilterPanel,
} from "@src/features/dashboard/utils/dashboard_utils";
import { ActiveFiltersBar } from "@src/features/table_filtering/components/active_filters_bar";
import { useGetManagersDashboardStatisticsQuery } from "@src/services/slices/managersSlice";
import { DashboardFilterPayload, Employee, FilterPanel } from "@src/types";
import { Col, Layout, Row } from "antd";
import Cookies from "universal-cookie";

const DashBoardPage = () => {
  const cookies = new Cookies();
  const empObj: Employee = cookies.get("loggedInuser");

  const [selectedCollapseFilter, setSelectedCollapseFilter] = useState<
    Map<string, string[]>
  >(new Map());
  const [timeRangeFilter, setTimeRangeFilter] = useState<number>(6);

  const dashboardFilterPayload: DashboardFilterPayload = {
    managerId: empObj.employeeId,
    monthRange: timeRangeFilter,
    ...Object.fromEntries(selectedCollapseFilter),
  };

  const { data: dashboardStatistics, currentData } =
    useGetManagersDashboardStatisticsQuery(dashboardFilterPayload);

  const filterCollapseData: FilterPanel[] = mapToFilterPanel(
    dashboardStatistics?.requests,
    selectedCollapseFilter
  );

  /**
   * Filters by the given time range filter. The time range filter filters the requestStartDate.
   * That means if a request start date is before the current date than the requests will also not be visible.
   * @param {number} selectedTimeRange - The selected time range in months (e.g. 3, 6, 12)
   */
  function onFilterTimeRange(selectedTimeRange: number) {
    setTimeRangeFilter(selectedTimeRange);
  }

  /**
   * Filters the dashboard statistics data by the given checkbox filter and will update the state so that the charts will be rendered again.
   * @param {string} filterType - The name of the current selected filters (e.g. "Regions")
   * @param {string[]} allCheckedValues - The current selected elements from the checkbox list (e.g. ["APAC", "Germany"])
   */
  function onFilterCollapse(filterType: string, allCheckedValues: string[]) {
    setSelectedCollapseFilter((currentFilter: Map<string, string[]>) => {
      if (allCheckedValues.length < 1) {
        currentFilter.delete(filterType);
        return new Map(currentFilter);
      }

      return new Map(currentFilter).set(filterType, allCheckedValues);
    });
  }

  /**
   *  Will give the current data update status. It will check the dashboard statistics with current data to see if
   *  there are changes or not. Maybe there is also a better alternative but there were problems
   *  with isLoading and isFetching from rtk that`s why this was implemented
   *  @return {boolean} The current updated status as boolean
   */
  function isLoading(): boolean {
    return !dashboardStatistics || dashboardStatistics !== currentData;
  }

  /**
   * Triggered when a filter gets removed in the active filter section
   * onClose function of an active filter tag
   * @param {string} filterType - The name of the current selected filters (e.g. "Regions")
   * @param {string[]} filterValueToBeRemoved - The filter value that is clicked for removing
   */
  function onRemoveActiveFilter(
    filterType: string,
    filterValueToBeRemoved: string
  ) {
    const filterValues: string[] = selectedCollapseFilter.get(filterType);

    const newSelectedFilterValues: string[] = filterValues.filter(
      (value: string) => value !== filterValueToBeRemoved
    );

    onFilterCollapse(filterType, newSelectedFilterValues);
  }

  /** Resets all filter */
  function deleteAllFilters() {
    setSelectedCollapseFilter(new Map());
  }

  return (
    <Layout className="management-dashboard">
      <div className="management-dashboard__content">
        <Headline title="Dashboard" />
        <Row gutter={[32, 32]} justify="center">
          <Col xl={24} flex="0 1 1600px">
            <ActiveFiltersBar
              activeFilters={mapToActiveFilter(selectedCollapseFilter)}
              onRemove={onRemoveActiveFilter}
              onRemoveAll={deleteAllFilters}
            />
          </Col>
        </Row>
        <div className="management-dashboard__charts">
          <Row gutter={[32, 32]} justify="center">
            <Col xl={12} flex="0 1 800px">
              <RequestStatusStatisticsChart
                data={dashboardStatistics?.requestStatusStatistics}
                isLoading={isLoading()}
              />
            </Col>
            <Col xl={12} flex="0 1 800px">
              <TotalFteChart
                data={dashboardStatistics?.totalFte}
                isLoading={isLoading()}
              />
            </Col>
          </Row>
          <Row gutter={[32, 32]} justify="center">
            <Col xl={12} flex="0 1 800px">
              <RequestCountryStatisticsChart
                requestCountryStatisticsData={
                  dashboardStatistics?.countryStatistics
                }
                isLoading={isLoading()}
              />
            </Col>
            <Col xl={12} flex="0 1 800px">
              <Row gutter={[32, 32]} wrap={false}>
                <Col span={14} className="ant-col--request-replies">
                  <RequestRepliedChart
                    requested={
                      dashboardStatistics?.requestStatusStatistics?.requested
                    }
                    replied={
                      dashboardStatistics?.requestStatusStatistics?.replied
                    }
                    isLoading={isLoading()}
                  />
                </Col>
                <Col span={10} className="ant-col--simple-custom-cards">
                  <SimpleCustomCard
                    data={
                      dashboardStatistics?.requestStatusStatistics
                        ?.requiredAction
                    }
                    title="Require action"
                    type="require-action"
                    isLoading={isLoading()}
                  />
                  <SimpleCustomCard
                    data={
                      dashboardStatistics?.requestStatusStatistics?.declinedTag
                    }
                    title="Declined requests"
                    type="decline"
                    isLoading={isLoading()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <Layout.Sider
        className="management-dashboard__filter"
        collapsed={false}
        width="300px"
        role="side-navigation"
      >
        <FilterSelection
          className="management-dashboard__month-range-select"
          headline="Time Range"
          options={getTimeRangeOptions()}
          onFilter={onFilterTimeRange}
          defaultValue={timeRangeFilter}
        />
        <FilterCollapse
          filterPanels={filterCollapseData}
          onFilter={onFilterCollapse}
          disabled={isLoading()}
        />
      </Layout.Sider>
    </Layout>
  );
};

export default DashBoardPage;
