import React, { useEffect, useState, createRef, useMemo } from "react";

import { ConfirmationModal } from "@src/components/modals/confirmation_modal";
import { EditProjectConfirmationModal } from "@src/components/modals/edit_project_confirmation_modal";
import {
  PROJECT_PHASE,
  TECHNOLOGY,
  PROJECT_FORM_VARIANT,
  ProjectTypeEnum,
} from "@src/constants";
import {
  getConfirmationModalMessagesCopyProject,
  getConfirmationModalMessagesCopyProjectWarning,
  getConfirmationModalMessages,
} from "@src/features/projects/utils/confirmation_modal_messages";
import {
  areEditorsUpdated,
  getInitialProjectValues,
  getPayload,
} from "@src/features/projects/utils/project_form_utils";
import CheckUserRoleService from "@src/services/checkUserRole";
import { useGetCountriesQuery } from "@src/services/slices/countriesApi";
import { useGetProjectLeadsQuery } from "@src/services/slices/employeesSlice";
import {
  useAddProjectMutation,
  useChangePeriodRequestsMutation,
  useDuplicateProjectMutation,
  useUpdateProjectMutation,
} from "@src/services/slices/projectsSlice";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  App,
} from "antd";
import "./project_form.less";
import dayjs from "dayjs";
import Cookies from "universal-cookie";
import { Headline } from "@src/components";

const cookies = new Cookies();

class CustomCalendarCell extends React.Component {
  constructor(props) {
    super(props);
    this.originalTitle = " ";
    this.originalClasses = " ";
    this.updated = false;
  }

  componentDidMount() {
    if (this.props.currNode.current && this.props.isDisabled) {
      this.updated = true;
      this.originalTitle = this.props.currNode.current.parentNode.title;
      this.originalClasses = this.props.currNode.current.parentNode.className;
      this.updateTitleAndClasses(
        this.props.message,
        this.originalClasses + " ant-picker-cell-disabled"
      );
    }
  }

  componentDidUpdate() {
    if (this.props.currNode.current) {
      if (!this.props.isDisabled && this.updated) {
        this.updateTitleAndClasses(this.originalTitle, this.originalClasses);
        this.updated = false;
      } else if (this.props.isDisabled) {
        this.updateTitleAndClasses(
          this.props.message,
          this.originalClasses + " ant-picker-cell-disabled"
        );
        this.updated = true;
      }
    }
  }

  updateTitleAndClasses(title, classes) {
    this.props.currNode.current.parentNode.title = title;
    this.props.currNode.current.parentNode.className = classes;
  }

  render() {
    return (
      <button
        ref={this.props.currNode}
        data-testid={dayjs(this.props.current).format("DD-MM-YYYY")} //this needs to be in this format because of cypress is not recognizing backslashes
        disabled={this.props.isDisabled}
        className={`ant-picker-cell-inner ${
          this.props.hideToday ? "hide-today" : "show-today"
        }`}
      >
        {this.props.current.date()}
      </button>
    );
  }
}

const ProjectForm = ({ project, onProjectSaved, onCancel, variant }) => {
  const { modal, message } = App.useApp();

  const [formErrors, setFormErrors] = useState(new Set());
  const [formValues, setFormValues] = useState({});
  const [ownerFieldsUpdated, setOwnerFieldsUpdated] = useState({});
  const [modalMessageCopyProject, setModalMessageCopyProject] = useState({});
  const [isAnyFieldUpdated, setIsAnyFieldUpdated] = useState(false);
  const [isJointVenture, setJointVenture] = useState(
    !!project?.jointVenturePartner
  );
  const [accessRightModalVisible, setAccessRightModalVisible] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [
    confirmationModalCopyProjectVisible,
    setConfirmationModalCopyProjectVisible,
  ] = useState(false);

  const { data: projectCountries } = useGetCountriesQuery();
  const { data: projectLeads, isLoading: isLoadingProjectLeads } =
    useGetProjectLeadsQuery();
  const [addProject] = useAddProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [changePeriodRequest] = useChangePeriodRequestsMutation();
  const [duplicateProject] = useDuplicateProjectMutation();

  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const empObj = cookies.get("loggedInuser");

  const initialValues = useMemo(() => {
    return getInitialProjectValues(project, empObj.fullName);
  }, [project, empObj.fullName]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [project, initialValues, form]);

  useEffect(() => {
    CheckUserRoleService.checkRole();
    if (project) {
      form.validateFields();
    }
  }, []);

  useEffect(() => {
    setJointVenture(!!project?.jointVenturePartner);
  }, [project]);

  const onValuesChange = (changedValues) => {
    const changedField = Object.keys(changedValues)[0];

    let changedValue = changedValues[changedField];
    let initialValue = initialValues[changedField];

    if (project && changedField === "projectDate") {
      initialValue = initialValues[changedField]
        .map((value) => value.toLocaleString())
        .toString();

      changedValue =
        changedValue &&
        changedValue.map((value) => value.toLocaleString()).toString();
    }

    if (changedField === "editors" || changedField === "projectManager") {
      ownerFieldsUpdated[changedField] = true;
    }

    if (
      (changedField === "editors" &&
        !areEditorsUpdated(changedValue, initialValue)) ||
      (changedField === "projectManager" && changedValue === initialValue)
    ) {
      delete ownerFieldsUpdated[changedField];
    }

    if (changedField === "jointVenture") {
      setJointVenture(changedValue);
    }

    setOwnerFieldsUpdated({ ...ownerFieldsUpdated });
    setIsAnyFieldUpdated(changedValue !== initialValue);
  };

  const onFinish = async (fieldsValue) => {
    if (variant === PROJECT_FORM_VARIANT.DUPLICATE && !isAnyFieldUpdated) {
      setModalMessageCopyProject(
        getConfirmationModalMessagesCopyProjectWarning()
      );
      setConfirmationModalCopyProjectVisible(true);
    } else {
      const ownerFields = ownerFieldsUpdated;
      if (ownerFields && Object.keys(ownerFields).length > 0) {
        setAccessRightModalVisible(true);
        setFormValues(fieldsValue);
      } else {
        handleOkAccessRightModal();
      }
    }
  };

  const handleOkAccessRightModal = () => {
    if (project) {
      if (
        variant === PROJECT_FORM_VARIANT.EDIT &&
        dayjs(initialValues.projectDate[1]).isAfter(
          dayjs(form.getFieldValue("projectDate")[1])
        )
      ) {
        setConfirmationModalVisible(true);
      } else {
        handleOkEndDateConfirmationModal();
      }
    } else {
      saveProject();
    }
  };

  const handleOkEndDateConfirmationModal = () => {
    if (variant === PROJECT_FORM_VARIANT.DUPLICATE) {
      handleCopyProjectModal();
    } else if (
      variant === PROJECT_FORM_VARIANT.EDIT &&
      initialValues.jointVenture &&
      !form.getFieldValue("jointVenture")
    ) {
      modal.confirm({
        autoFocusButton: null,
        content:
          "You are about to remove the JV feature from the project. If you remove this feature, all the relevant requests will be saved as 'Draft'. Would you like to continue?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          saveProject();
        },
      });
    } else {
      saveProject();
    }
  };

  const handleCopyProjectModal = () => {
    setModalMessageCopyProject(getConfirmationModalMessagesCopyProject());
    setConfirmationModalCopyProjectVisible(true);
  };

  const onClickSave = () => {
    form.submit();
  };

  const checkForChangePeriodRequestsAndSave = (fieldsValue) => {
    if (
      dayjs(initialValues.projectDate[1]).isAfter(
        dayjs(fieldsValue.projectDate[1])
      )
    ) {
      changePeriodRequest(project.id)
        .unwrap()
        .then(() => {
          onProjectSaved(fieldsValue.projectManager, fieldsValue.editors);
        })
        .catch(() => {
          message.error("Failed to change requests", 5);
        });
    } else {
      onProjectSaved(fieldsValue.projectManager, fieldsValue.editors);
    }
  };

  const saveProject = () => {
    const fieldsValue = form.getFieldsValue();
    let values = getPayload(fieldsValue, projectLeads, project?.id);
    switch (variant) {
      case PROJECT_FORM_VARIANT.EDIT:
        updateProject(values)
          .unwrap()
          .then(() => {
            checkForChangePeriodRequestsAndSave(fieldsValue);
          })
          .catch(() => message.error("Failed to update project"));
        break;
      case PROJECT_FORM_VARIANT.DUPLICATE:
        duplicateProject(values)
          .unwrap()
          .then(() => onProjectSaved())
          .catch(() => message.error("Failed to duplicate project"));
        break;
      case PROJECT_FORM_VARIANT.CREATE:
        addProject(values)
          .unwrap()
          .then(() => onProjectSaved())
          .catch(() => message.error("Failed to create project"));
        break;
      default:
    }
  };

  /* validators */
  const validateRequiredFields = (rule, value) => {
    const column = rule.field;

    const startDate = form.getFieldValue("projectDate")?.[0].toDate();
    const endDate = form.getFieldValue("projectDate")?.[1].toDate();
    const stageGateDate = form.getFieldValue("stageGateDate");

    const errorHandling = (msg) => {
      setFormErrors((formErrors) => new Set(formErrors.add(column)));
      return Promise.reject(msg);
    };

    if (column === "stageGateDate") {
      if (!endDate || !startDate) {
        return errorHandling(` You have to select first start and end date`);
      }

      if (dayjs(endDate).isBefore(dayjs(stageGateDate))) {
        return errorHandling(` Stage gate date must be before end date`);
      }

      if (dayjs(startDate).isAfter(dayjs(stageGateDate))) {
        return errorHandling(`Stage gate date must be after start date`);
      }
    }

    if (!value) {
      return errorHandling(`This field is required`);
    }

    if (formErrors.has(column)) {
      let updatedErrorNodes = new Set(formErrors);
      updatedErrorNodes.delete(column);
      setFormErrors(new Set(updatedErrorNodes));
    }

    return Promise.resolve();
  };

  const disableStageGate = () => {
    if (!form.getFieldValue("projectDate")) return true;

    const startDate = form.getFieldValue("projectDate")[0];
    const endDate = form.getFieldValue("projectDate")[1];
    return !endDate || !startDate;
  };

  const InputLoadingPlaceholder = () => (
    <div className="placeholder-input">loading... </div>
  );

  return (
    <div
      className={`project-form ${
        variant !== PROJECT_FORM_VARIANT.EDIT && "project-creation"
      }`}
    >
      {variant !== PROJECT_FORM_VARIANT.EDIT && (
        <Headline title="Create new project" />
      )}
      <h3>General</h3>
      <Form
        id="myForm"
        form={form}
        autoComplete="off"
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="Name of the project"
              name="name"
              validateTrigger="onBlur"
              rules={[{ validator: validateRequiredFields }]}
            >
              <Input
                data-testid="project-name"
                placeholder="for example 'hermes'"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Project Type"
              name="projectType"
              validateTrigger="onBlur"
            >
              <Select
                data-testid="project-type"
                placeholder="select project type"
                allowClear
                options={Object.values(ProjectTypeEnum).map((projectType) => ({
                  key: projectType,
                  value: projectType,
                  label: projectType,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="Project Owner"
              name="projectManager"
              rules={[{ validator: validateRequiredFields }]}
            >
              {isLoadingProjectLeads ? (
                <InputLoadingPlaceholder />
              ) : (
                <Select
                  showSearch
                  disabled={
                    project &&
                    project.projectManager.employeeId !== empObj.employeeId
                  }
                  optionFilterProp="children"
                  data-testid="project-manager"
                  placeholder="select or search Project Owner"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0 ||
                    option.props.value
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0
                  }
                >
                  {projectLeads?.map((projectLead) => {
                    return (
                      <Select.Option
                        data-testid={projectLead.employeeId}
                        key={projectLead.employeeId}
                        value={projectLead.fullName}
                      >
                        {projectLead.fullName}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Project Editor" name="editors">
              {isLoadingProjectLeads ? (
                <InputLoadingPlaceholder />
              ) : (
                <Select
                  data-testid="project-editor"
                  disabled={
                    project &&
                    project.projectManager.employeeId !== empObj.employeeId
                  }
                  mode="multiple"
                  showArrow="true"
                  showSearch
                  placeholder="select or search project editor"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0 ||
                    option.props.value
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0
                  }
                >
                  {projectLeads?.length > 0 &&
                    projectLeads?.map((projectLead) => {
                      return (
                        <Select.Option
                          data-testid={projectLead.employeeId}
                          key={projectLead.employeeId}
                          value={projectLead.fullName}
                        >
                          {projectLead.fullName}
                        </Select.Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <h3>Project Dates & Locations</h3>
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="Date"
              name="projectDate"
              className="project-range-picker"
              rules={[{ validator: validateRequiredFields }]}
            >
              <DatePicker.RangePicker
                inputReadOnly={true}
                data-testid="project-range-picker"
                format={dateFormat}
                showNow={false}
                id={{ start: "start", end: "end" }}
                onChange={(date) => {
                  if (!date) {
                    form.setFieldsValue({ stageGateDate: null });
                  }

                  form.validateFields(["stageGateDate"]);
                }}
                cellRender={(current) => {
                  const currNode = createRef();
                  const message = project
                    ? "Conflict with resource plan"
                    : "Start date must be before end date";

                  return (
                    <CustomCalendarCell
                      currNode={currNode}
                      current={current}
                      message={message}
                    />
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="Phase"
              name="phase"
              rules={[{ validator: validateRequiredFields }]}
            >
              <Select
                data-testid="project-phase"
                placeholder="select phase"
                onChange={(selectedProjectPhase) => {
                  if (
                    project &&
                    project.projectPhase === selectedProjectPhase &&
                    project.stageGateDate
                  ) {
                    form.setFieldsValue({
                      stageGateDate: dayjs(project.stageGateDate),
                    });
                  } else {
                    form.setFieldsValue({ stageGateDate: null });
                  }
                  form.validateFields(["stageGateDate"]);
                }}
              >
                {PROJECT_PHASE.map((phase) => {
                  return (
                    <Select.Option
                      data-testid={phase}
                      key={phase}
                      value={phase}
                    >
                      {phase}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Stage gate date (estimated)"
              name="stageGateDate"
              rules={[{ validator: validateRequiredFields }]}
            >
              <DatePicker
                inputReadOnly={true}
                placeholder="select date"
                data-testid="stage-gate-date"
                format={dateFormat}
                showNow={false}
                validateTrigger="onChange"
                disabled={disableStageGate()}
                cellRender={(current, info) => {
                  const currNode = createRef();
                  const message = project
                    ? "Conflict with resource plan"
                    : "Start date must be before end date";

                  let isBeforeStartDate = false;
                  let isAfterEndDate = false;
                  let isDisabled;

                  const startDate = form.getFieldValue("projectDate")?.[0];
                  const endDate = form.getFieldValue("projectDate")?.[1];

                  if (current && initialValues) {
                    isBeforeStartDate = current.isBefore(startDate);
                    isAfterEndDate = current.isAfter(endDate);
                  }

                  isDisabled = isAfterEndDate || isBeforeStartDate;

                  const hideToday =
                    startDate !== "" && !info.today.isSame(startDate, "day");

                  return (
                    <CustomCalendarCell
                      currNode={currNode}
                      current={current}
                      isDisabled={isDisabled}
                      message={message}
                      hideToday={hideToday}
                    />
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="countryId"
              rules={[{ validator: validateRequiredFields }]}
            >
              <Select
                data-testid="project-country"
                showSearch
                placeholder="select or search country"
                optionFilterProp="children"
                onChange={(id) => {
                  const country = projectCountries.find(
                    (c) => c.countryId === id
                  );
                  if (country?.regionName) {
                    form.setFieldsValue({ region: country.regionName });
                  } else {
                    form.setFieldsValue({ region: "no region selected" });
                  }
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) >= 0 ||
                  option.props.value
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) >= 0
                }
              >
                {projectCountries?.length > 0 ? (
                  projectCountries.map((country) => {
                    return (
                      <Select.Option
                        data-testid={country.countryName}
                        key={country.countryId}
                        value={country.countryId}
                      >
                        {country.countryName}
                      </Select.Option>
                    );
                  })
                ) : (
                  <h1>Loading...</h1>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Region" name="region">
              <Input
                disabled={true}
                data-testid="region"
                placeholder="no region selected"
              />
            </Form.Item>
          </Col>
        </Row>

        <h3>Additional Infos</h3>

        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item name="technologies" label="Technology">
              <Checkbox.Group options={Object.values(TECHNOLOGY)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Joint Venture" className="joint-venture">
              <Form.Item name="jointVenture" valuePropName="checked">
                <Switch data-testid="joint-venture-switch" />
              </Form.Item>
              <Form.Item
                name="jointVenturePartner"
                className="joint-venture-partner"
                validateTrigger="onBlur"
                dependencies={["jointVenture"]}
                rules={
                  form.getFieldValue("jointVenture")
                    ? [{ validator: validateRequiredFields }]
                    : []
                }
              >
                {isJointVenture && (
                  <Input
                    data-testid="joint-venture-partner"
                    placeholder="Joint venture partner"
                  />
                )}
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={onCancel} data-testid="cancel-project-button">
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={
                (variant === PROJECT_FORM_VARIANT.EDIT && !isAnyFieldUpdated) ||
                formErrors.size > 0 ||
                isLoadingProjectLeads
              }
              data-testid="save-project-button"
              className="save-project-button"
              onClick={onClickSave}
              loading={isLoadingProjectLeads}
            >
              Save project
            </Button>
          </Col>
        </Row>
      </Form>

      {/* modal for information if new endDate will be before the old endDate */}
      <ConfirmationModal
        action={handleOkEndDateConfirmationModal}
        modalVisible={confirmationModalVisible}
        setModalVisible={setConfirmationModalVisible}
        modalMessage={getConfirmationModalMessages()}
      />

      {/* modal for information if project should be duplicated, especially if there are no changes */}
      <ConfirmationModal
        action={saveProject}
        modalVisible={confirmationModalCopyProjectVisible}
        setModalVisible={setConfirmationModalCopyProjectVisible}
        modalMessage={modalMessageCopyProject}
      />

      {/* modal for editors/access rights */}
      <EditProjectConfirmationModal
        formValues={formValues}
        action={handleOkAccessRightModal}
        modalVisible={accessRightModalVisible}
        setModalVisible={setAccessRightModalVisible}
      />
    </div>
  );
};

export default ProjectForm;
