import React, { useEffect, useState } from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import settingsIcon from "@src/assets/icons/settings.svg";
import "./table_settings_button.less";
import {
  getPropertyValuesOfData,
  mapColumnsToTreeData,
} from "@src/features/table_filtering/utils/filter_utils";
import { TableSettingsConfig } from "@src/types";
import { TreeNode } from "@src/types/antd_types";
import { areArraysIdentical } from "@src/utils/helper";
import { Modal, Tree, Button, Tooltip, Switch } from "antd";
import _ from "lodash";

export interface TableSettingsButtonProps {
  className?: string;
  disabled?: boolean;
  tableSettings: TableSettingsConfig;
}

const TableSettingsButton: React.FC<TableSettingsButtonProps> = ({
  className,
  disabled = false,
  tableSettings,
}) => {
  const [defaultHiddenColumns, setDefaultHiddenColumns] = useState<string[]>(
    tableSettings.defaultHiddenColumns
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [compactMode, setCompactMode] = useState<boolean>(false);
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  function filterDisplayableColumns(columns: TreeNode[]) {
    return columns
      .filter((column) => !tableSettings?.columnsToHide?.includes(column.key))
      .filter((column) =>
        compactMode
          ? !tableSettings?.compactViewSettings?.expandedColumns?.includes(
              column.key
            )
          : !tableSettings?.compactViewSettings?.compactedColumns?.includes(
              column.key
            )
      );
  }

  const columnTreeData: TreeNode[] = filterDisplayableColumns(
    mapColumnsToTreeData(tableSettings?.columns, "headerName")
  );

  /**
   * Checks if a column is visible based on the current table settings.
   *
   * @param {string} key - The header name of the column to check visibility for.
   * @returns {boolean} - Returns true if the column is visible, otherwise false.
   */
  function checkIfColumnIsVisible(key: string): boolean {
    const column = tableSettings?.columns.find(
      (column) => column.headerName === key
    );

    const defaultHiddenColumns = tableSettings?.compactViewSettings
      ? tableSettings?.defaultHiddenColumns?.filter((col) =>
          compactMode
            ? !tableSettings.compactViewSettings.compactedColumns.includes(col)
            : !tableSettings.compactViewSettings.expandedColumns.includes(col)
        )
      : tableSettings?.defaultHiddenColumns;

    return column?.hide !== true && !defaultHiddenColumns?.includes(key);
  }

  /**
   * Load default column keys to state if filter is not applied
   */
  useEffect(() => {
    if (!tableSettings) {
      return;
    }

    const keys: string[] = getPropertyValuesOfData(columnTreeData, "key");
    const checkedKeys =
      tableSettings?.selectedColumns?.length > 0
        ? tableSettings.selectedColumns
        : keys.filter((key: string) => checkIfColumnIsVisible(key));

    // Only update state if checkedKeys have changed
    setCheckedKeys(checkedKeys);
    setLastSelectedColumns(checkedKeys);
    tableSettings.onChange(checkedKeys);
  }, [JSON.stringify(tableSettings)]);

  /**
   * When tabs are changed the default hidden columns should be reset to the new ones
   */
  useEffect(() => {
    if (
      tableSettings.defaultHiddenColumns &&
      !areArraysIdentical(
        tableSettings.defaultHiddenColumns,
        defaultHiddenColumns
      )
    ) {
      setDefaultHiddenColumns(tableSettings.defaultHiddenColumns);
      const keys = getPropertyValuesOfData(columnTreeData, "key");
      const checkedKeys = keys.filter((key) => checkIfColumnIsVisible(key));
      setCheckedKeys(checkedKeys);
      setLastSelectedColumns(checkedKeys);
      tableSettings.onChange(checkedKeys);
    }
  }, [JSON.stringify(tableSettings.defaultHiddenColumns)]);

  useEffect(() => {
    if (tableSettings.compactViewSettings) {
      setCompactMode(tableSettings.compactViewSettings.defaultCompacted);
    }
  }, [JSON.stringify(tableSettings.compactViewSettings)]);

  function handleSettingsClick() {
    setIsModalOpen(true);
  }

  function handleOk() {
    setLastSelectedColumns(checkedKeys);
    tableSettings.onChange(checkedKeys);
    setIsModalOpen(false);
  }

  function handleCancel() {
    setCheckedKeys(lastSelectedColumns);
    setIsModalOpen(false);
  }

  function onCheck(values: string[]) {
    setCheckedKeys(values);
  }

  /**
   * Toggles the table view mode between compact and expanded, updating the checked columns accordingly.
   */
  function onSwitchChange() {
    // Destructure compacted and expanded columns from table settings
    const { compactedColumns, expandedColumns } =
      tableSettings?.compactViewSettings || {};
    const isCompactMode = !compactMode; // Determine the new mode

    // Determine which columns to toggle based on the new mode
    const columnsToToggle = isCompactMode ? compactedColumns : expandedColumns;
    const columnsToKeep = isCompactMode ? expandedColumns : compactedColumns;

    // Update checked columns by toggling relevant columns and keeping existing checked ones
    const newColumns = [...columnsToToggle, ...checkedKeys].filter(
      (key) => !columnsToKeep?.includes(key)
    );

    // Set the new state for checked columns and mode
    setCheckedKeys(newColumns);
    setCompactMode(isCompactMode);
  }

  return (
    <>
      <Tooltip mouseEnterDelay={0.5} placement="top" title={"Table settings"}>
        <Button
          className={className}
          htmlType="submit"
          icon={<img src={settingsIcon} alt={"settings icon"} />}
          disabled={disabled}
          onClick={handleSettingsClick}
          size="large"
          data-testid="settings-button"
        />
      </Tooltip>
      <Modal
        title="Table Configuration"
        width={399}
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        okButtonProps={{
          icon: <CheckOutlined />,
          size: "large",
        }}
        onCancel={handleCancel}
        cancelButtonProps={{
          icon: <CloseOutlined />,
          size: "large",
        }}
        data-testid="table-settings-modal"
        className="table-settings-modal"
      >
        <h5 className="table-settings-headline">Active Table Cols</h5>
        <div className="divider" />
        {tableSettings.compactViewSettings && (
          <div className="compact-switch-wrapper">
            <Switch defaultChecked={true} onChange={onSwitchChange} />
            <span>Compact View</span>
          </div>
        )}
        <Tree
          checkable
          virtual={false}
          motion={false}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          treeData={_.cloneDeep(columnTreeData)}
          selectable={false}
          data-testid="table-cols-tree"
          className="table-cols-checkbox-list"
        />
      </Modal>
    </>
  );
};

export default TableSettingsButton;
