// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-phase {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.project-phase-technology-icon {
  height: 32px;
  width: 32px;
}
.project-phase-hydrogen-icon {
  height: 24px;
  width: 24px;
  margin: 0 !important;
}
.project-phase-profile-icon {
  height: 12px;
  width: 12px;
  margin: 0 !important;
}
.project-phase-info-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.project-phase-text {
  font-size: 14px !important;
  font-family: "RWESansWeb-Medium", sans-serif !important;
}
.project-phase-subtext {
  display: flex;
  gap: 3px;
  align-items: center;
  font-family: "RWESansWeb-Regular";
  font-size: 12px !important;
  color: #3b3d42;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/custom_renderer/project_phase_renderer/project_phase_renderer.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,8BAAA;AADF;AAGE;EACE,YAAA;EACA,WAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;EACA,oBAAA;AAFJ;AAKE;EACE,YAAA;EACA,WAAA;EACA,oBAAA;AAHJ;AAME;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAJJ;AAOE;EACE,0BAAA;EACA,uDAAA;AALJ;AAQE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,iCAAA;EACA,0BAAA;EACA,cAAA;AANJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.project-phase {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  justify-content: space-between;\n\n  &-technology-icon {\n    height: 32px;\n    width: 32px;\n  }\n\n  &-hydrogen-icon {\n    height: 24px;\n    width: 24px;\n    margin: 0 !important;\n  }\n\n  &-profile-icon {\n    height: 12px;\n    width: 12px;\n    margin: 0 !important;\n  }\n\n  &-info-wrapper {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n  }\n\n  &-text {\n    font-size: 14px !important;\n    font-family: \"RWESansWeb-Medium\", sans-serif !important;\n  }\n\n  &-subtext {\n    display: flex;\n    gap: 3px;\n    align-items: center;\n    font-family: \"RWESansWeb-Regular\";\n    font-size: 12px !important;\n    color: @font-00;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
