import { EndpointBuilder } from "@reduxjs/toolkit/src/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import {
  DeleteRequest,
  EditRequestPayload,
  Project,
  ProjectPayload,
  ProjectRoleRequest,
  RequestReplies,
  ReassignProjectLeadPayload,
  SaveRequestPayload,
  SendRequestPayload,
} from "@src/types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const projectsSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),

    /* PUT endpoints */
    ...putEndpoints(builder),

    /* DELETE endpoints */
    ...deleteEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get a single project by a given id
     */
    getProject: builder.query<Project, string>({
      query: (id) => `/project/${id}`,
      transformErrorResponse: async () => {
        message.error("Error loading the requested project", 5);
      },
      providesTags: ["ProjectDetails"],
    }),

    /**
     * get all requests and replies for a given project id
     */
    getRequestsReplies: builder.query<RequestReplies[], string>({
      query: (projectId) => `/projects/${projectId}/requests-replies`,
      transformErrorResponse: async () => {
        message.error("Error loading the request replies", 5);
      },
      providesTags: ["Requests"],
    }),

    /**
     * get all requests for a given project id
     */
    getRequests: builder.query<ProjectRoleRequest[], string>({
      query: (projectId) => `/projects/${projectId}/requests`,
      transformErrorResponse: async () => {
        message.error("Error loading the request", 5);
      },
      providesTags: ["Requests"],
    }),

    //Todo: change this endpoint to /projects
    /**
     * get all projects for the admin page
     */
    getAllProjects: builder.query<Project[], void>({
      query: () => "/all-projects",
      transformResponse(response) {
        return response.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      },
      transformErrorResponse: async () => {
        message.error("Failed to get projects list", 5);
      },
      providesTags: ["Projects"],
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.POST;

  return {
    /**
     * Create a new project with the given data
     */
    addProject: builder.mutation<void, Project>({
      query: (project) => ({
        url: "/projects",
        method: baseMethod,
        body: project,
      }),
      transformErrorResponse: async () => {
        message.error("Error creating new project", 5);
      },
      invalidatesTags: ["Projects"],
    }),

    /**
     * Edit requests for a given project id
     */
    editRequest: builder.mutation<ProjectRoleRequest, EditRequestPayload>({
      query: ({ projectId, editedRequests }) => ({
        url: `/projects/${projectId}/edit-request`,
        method: baseMethod,
        body: editedRequests,
      }),
      transformErrorResponse: async () => {
        message.error("Error updating request", 5);
      },
      invalidatesTags: ["Requests", "AdminStandardRoles"],
    }),

    /**
     * Add requests
     */
    sendRequests: builder.mutation<void, SendRequestPayload[]>({
      query: (selectedRequests) => ({
        url: `/projects/send-requests`,
        method: baseMethod,
        body: selectedRequests,
      }),
      transformErrorResponse: async () => {
        message.error("Error sending request", 5);
      },
      invalidatesTags: ["Requests", "AdminStandardRoles"],
    }),

    /**
     * Duplicate a project by a given id
     */
    duplicateProject: builder.mutation<void, ProjectPayload>({
      query: (project) => ({
        url: `/projects/${project.projectId}/duplicate`,
        method: baseMethod,
        body: project,
      }),
      transformErrorResponse: async () => {
        message.error("Error duplicating project", 5);
      },
      invalidatesTags: ["Projects"],
    }),

    /**
     * Add a new open request to a given project id
     */
    saveRequest: builder.mutation<void, SaveRequestPayload>({
      query: ({ projectId, updatedRequest }) => ({
        url: `/projects/${projectId}/update-draft`,
        method: baseMethod,
        body: updatedRequest,
      }),
      transformErrorResponse: async () => {
        message.error("Error saving request project", 5);
      },
      invalidatesTags: ["Requests", "AdminStandardRoles"],
    }),
  };
}

function putEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.PUT;
  return {
    /**
     * Change the period of requests for a given project id
     */
    changePeriodRequests: builder.mutation<void, string>({
      query: (projectId) => ({
        url: `/projects/${projectId}/change-period-requests`,
        method: baseMethod,
        body: {},
      }),
      transformErrorResponse: async () => {
        message.error("Error changing period requests for project", 5);
      },
      invalidatesTags: ["Projects"],
    }),

    /**
     * Update a project with the given data
     */
    updateProject: builder.mutation<void, ProjectPayload>({
      query: (project) => ({
        url: `/projects/${project.projectId}`,
        method: baseMethod,
        body: project,
      }),
      transformErrorResponse: async () => {
        message.error("Error updating project", 5);
      },
      invalidatesTags: ["ProjectDetails"],
    }),

    /**
     * Update project leads
     */
    updatedProjectsResponse: builder.mutation<void, Project[]>({
      query: (project) => ({
        url: `project/bulk-update-pl`,
        method: baseMethod,
        body: project,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to update project leads", 5);
      },
      invalidatesTags: ["Projects"],
    }),

    /**
     * Update project leads response
     */
    updateProjectLeadsResponse: builder.mutation<
      void,
      ReassignProjectLeadPayload
    >({
      query: ({ oldLeadId, newProjectLeadId }) => ({
        url: `projects/update-pl?oldProjectLead=${oldLeadId}&newProjectLead=${newProjectLeadId}`,
        method: baseMethod,
        body: {},
      }),
      transformErrorResponse: async () => {
        message.error("Failed to update project leads", 5);
      },
      invalidatesTags: ["Projects"],
    }),

    /**
     * Decline a request for a given project role request id
     */
    declineRequest: builder.mutation<void, string>({
      query: (projectRoleRequestId) => ({
        url: `request/${projectRoleRequestId}/decline`,
        method: baseMethod,
        body: {},
      }),
      transformErrorResponse: async () => {
        message.error("Error declining request", 5);
      },
      invalidatesTags: ["Requests"],
    }),

    /**
     * Accept a request for a given project role request id
     */
    acceptRequest: builder.mutation<void, string>({
      query: (projectRoleRequestId) => ({
        url: `request/${projectRoleRequestId}/accept`,
        method: baseMethod,
        body: {},
      }),
      transformErrorResponse: async () => {
        message.error("Error accepting request", 5);
      },
      invalidatesTags: ["Requests"],
    }),
  };
}

function deleteEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.DELETE;

  return {
    /**
     * Delete a single project by a given id
     */
    deleteProject: builder.mutation<void, number>({
      query: (id) => ({
        url: `/projects/${id}`,
        method: baseMethod,
      }),
      transformErrorResponse: async () => {
        message.error("Error deleting project", 5);
      },
      invalidatesTags: ["Projects"],
    }),

    /**
     * Delete multiple requests by a given list of ids
     */
    deleteRequest: builder.mutation<void, DeleteRequest[]>({
      query: (requestIdsToDelete) => ({
        url: `/projects/delete-requests`,
        method: baseMethod,
        body: requestIdsToDelete,
      }),
      transformErrorResponse: async () => {
        message.error("Error deleting requests", 5);
      },
      invalidatesTags: ["Requests"],
    }),

    /**
     * Delete requests cascading
     */
    deleteRequestCascading: builder.mutation<void, string>({
      query: (projectRoleRequestId) => ({
        url: `projects/delete-request-cascading/${projectRoleRequestId}`,
        method: baseMethod,
      }),
      transformErrorResponse: async () => {
        message.error("Error deleting requests", 5);
      },
      invalidatesTags: ["Requests"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetProjectQuery,
  useGetRequestsRepliesQuery,
  useGetRequestsQuery,
  useGetAllProjectsQuery,
  //lazy endpoints
  useLazyGetRequestsQuery,
  useLazyGetProjectQuery,
  useLazyGetRequestsRepliesQuery,

  /* POST endpoints */
  useAddProjectMutation,
  useEditRequestMutation,
  useSendRequestsMutation,
  useDuplicateProjectMutation,
  useSaveRequestMutation,

  /* PUT endpoints */
  useUpdateProjectMutation,
  useUpdatedProjectsResponseMutation,
  useUpdateProjectLeadsResponseMutation,
  useChangePeriodRequestsMutation,
  useDeclineRequestMutation,
  useAcceptRequestMutation,

  /* DELETE endpoints */
  useDeleteProjectMutation,
  useDeleteRequestMutation,
  useDeleteRequestCascadingMutation,
} = projectsSlice;
