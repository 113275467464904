import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Headline } from "@src/components/headline";
import ResetFilterButton from "@src/components/reset_filter_button/reset_filter_button";
import { ROLES } from "@src/constants";
import { EmployeeForm } from "@src/features/admin_role_assignments/components/employee_form";
import { DeleteEmployeeModal } from "@src/features/employee_data/components/delete_employee_modal";
import { ShowMoreEmployeeDataModal } from "@src/features/employee_data/components/show_more_employee_data_modal";
import { statusBar } from "@src/features/employee_data/utils/employee_table_utils";
import {
  getComponents,
  getGridOptions,
} from "@src/features/employee_data/utils/table_columns";
import { DownloadButton } from "@src/features/table_filtering/components/download_button";
import {
  useGetDepartmentsQuery,
  useLazyGetDepartmentsQuery,
} from "@src/services/slices/departmentsSlice";
import {
  useAssignRolesV2Mutation,
  useCheckEmployeeStatusMutation,
  useGetEmployeeApplicationRolesQuery,
  useGetEmployeesQuery,
  useLazySearchEmployeesQuery,
  useUpdateEmployeeMutation,
} from "@src/services/slices/employeesSlice";
import {
  useGetStandardRolesQuery,
  useLazyGetStandardRolesQuery,
} from "@src/services/slices/standardRolesApi";
import {
  ApplicationRole,
  AssignRolesPayload,
  Department,
  Employee,
  EmployeeDeleteStatus,
  EmployeeSearchRequest,
  EmployeeSortSpec,
  Page,
  StandardRole,
  UpdateEmployeePayload,
} from "@src/types";
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  IServerSideDatasource,
  RowNode,
} from "ag-grid-community";
import { IServerSideGetRowsParams } from "ag-grid-community/dist/lib/interfaces/iServerSideDatasource";
import { AgGridReact } from "ag-grid-react";
import { Button, message } from "antd";
import _ from "lodash";
import Cookies from "universal-cookie";
import "./employee_data_page.less";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

export interface EmployeeDataContext {
  applicationRoles: ApplicationRole[] | undefined;
  standardRoles: StandardRole[] | undefined;
  getStandardRoles: LazyQueryTrigger<
    QueryDefinition<void, any, any, StandardRole[], any>
  >;
  searchEmployees: LazyQueryTrigger<
    QueryDefinition<EmployeeSearchRequest, any, any, Page<Employee>, any>
  >;
  teamLeads: Employee[];
  orgCodes: Department[];
  employees: Employee[];
}

export interface EmployeeDataComponent {
  showMoreInformationRenderer: (
    params: ICellRendererParams
  ) => React.ReactElement;
  showApplicationRoleRenderer: (
    params: ICellRendererParams
  ) => React.ReactElement;
  showDepartmentRenderer: (params: ICellRendererParams) => React.ReactElement;
  showRoleRenderer: (params: ICellRendererParams) => React.ReactElement;
}

// remove all any
const EmployeeDataPage = () => {
  const gridApi = useRef<GridApi>(null);
  const cookies = new Cookies();

  /** states */
  const [employeeDeleteStatus, setEmployeeDeleteStatus] = useState<
    EmployeeDeleteStatus[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<Employee[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showAddEmployeeModal, setShowAddEmployeeModal] =
    useState<boolean>(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [showMoreEmployeeData, setShowMoreEmployeeData] =
    useState<Employee>(null);
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** rtk queries */
  const { data: standardRolesData } = useGetStandardRolesQuery();
  const { data: applicationRolesData } = useGetEmployeeApplicationRolesQuery();
  const [getStandardRoles] = useLazyGetStandardRolesQuery();
  const [getDepartments] = useLazyGetDepartmentsQuery();
  const { data: leads } = useGetEmployeesQuery({
    filterParams: { roles: [ROLES.TEAM_LEAD, ROLES.PROJECT_LEAD] },
  });
  const [searchEmployees] = useLazySearchEmployeesQuery();
  const { data: departmentsData } = useGetDepartmentsQuery();

  /** rtk mutations */
  const [checkEmployeeStatus] = useCheckEmployeeStatusMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [updateAccountRole] = useAssignRolesV2Mutation();

  /** use memos **/
  const context = useMemo<EmployeeDataContext>(
    () => ({
      applicationRoles: applicationRolesData,
      standardRoles: standardRolesData,
      getStandardRoles,
      getDepartments,
      searchEmployees,
      teamLeads: leads?.content.filter((employee) =>
        employee.applicationRoles.includes(ROLES.TEAM_LEAD)
      ),
      orgCodes: departmentsData,
      employees: _.cloneDeep(employeeData),
    }),
    [
      standardRolesData,
      applicationRolesData,
      getStandardRoles,
      leads,
      employeeData,
      departmentsData,
    ]
  );

  const components = useMemo<EmployeeDataComponent>(
    () => getComponents(setShowMoreEmployeeData, setShowMore),
    [getComponents]
  );

  const gridOptions = useMemo<GridOptions>(
    () => getGridOptions(),
    [getGridOptions]
  );

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
    params.api.setServerSideDatasource({
      getRows: fetchTableData as IServerSideDatasource["getRows"],
    });
  }, []);

  /** use effects **/
  useEffect(() => {
    // Required to refresh the grid after the data has been fetched
    gridApi.current?.refreshServerSide({});
  }, [applicationRolesData, standardRolesData, leads, departmentsData]);

  /**
   * Fetches the employee data from the server based on the filter and sort parameters provided by the grid.
   * @param params The parameters provided by the grid for fetching the data
   */
  async function fetchTableData(params: IServerSideGetRowsParams) {
    setIsLoading(true);
    const pageSize = params.request.endRow - params.request.startRow;
    const appliedFilters = params.request.filterModel;
    const sortModel = params.request.sortModel?.[0];

    const request: EmployeeSearchRequest = {
      pageRequestParams: {
        page: params.request.startRow / pageSize,
        size: pageSize,
      },
      sortRequestParams: sortModel
        ? [
            {
              field: getSortFieldFromColumnName(sortModel.colId),
              direction: sortModel.sort,
            },
          ]
        : null,
      filterParams: {
        fullName: appliedFilters?.fullName?.filter,
        orgCodes: appliedFilters?.departmentId?.values,
        uiNumber: appliedFilters?.uiNumber?.filter,
        positionId: appliedFilters?.deskId?.filter,
        roles: appliedFilters?.applicationRoles?.values,
        standardRoles: appliedFilters?.genericRoleId?.values,
        teamLeadFullNames: appliedFilters?.teamLeadFullName?.values,
      },
    };

    try {
      const employeeDataPage: Page<Employee> = await searchEmployees(
        request
      ).unwrap();
      setIsLoading(false);
      setEmployeeData(employeeDataPage.content);

      params.success({
        rowData: _.cloneDeep(employeeDataPage.content),
        rowCount: employeeDataPage.totalElements,
      });
    } catch (error) {
      console.error("Error while fetching employee data", error);
      params.fail();
    }
  }

  /**
   * Column names may differ from the field that the API expects for sorting. This function maps the column name to the
   * field name that the API expects.
   * @param columnNameId The ID of the column name
   */
  function getSortFieldFromColumnName(
    columnNameId: string
  ): EmployeeSortSpec["field"] | undefined {
    // Define a mapping from column name id to EmployeeSortSpec field
    const columnToFieldMap: Record<string, EmployeeSortSpec["field"]> = {
      fullName: "fullName",
      uiNumber: "uiNumber",
      deskId: "positionId",
      departmentId: "orgCode",
      applicationRoles: "role",
      genericRoleId: "standardRole",
      teamLeadFullName: "teamLeadFullName",
    };
    return columnToFieldMap[columnNameId];
  }

  /**
   * Will check if the employee can be deleted or if for example for the projekt lead a new project should be selected.
   */
  async function onDeleteEmployee(): Promise<void> {
    checkEmployeeStatus(selectedItems)
      .unwrap()
      .then((data) => {
        setEmployeeDeleteStatus(data);
        setModalDeleteVisible(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * Will undo the selected rows back to the old values.
   */
  function onUndo(): void {
    for (const selectedEmployee of selectedItems) {
      // value may be overwritten due to call by reference by ag grid.
      // That`s why it is necessary to clone the employee object to prevent the value to be updated so that an undo is still possible
      const foundEmployee: Employee = _.cloneDeep(context.employees).find(
        (employee: Employee) =>
          employee.employeeId === selectedEmployee.employeeId
      );

      //we have to pass the row node data to the updateRowNode function and not only the foundEmployee data,
      //because ag grid has still a reference to the node when ou handover the data of the node and can later update the row
      const foundEmployeeRowNode: RowNode<Employee> =
        gridApi.current.getRowNode(foundEmployee.employeeId.toString());
      foundEmployeeRowNode.data = foundEmployee;
      gridApi.current.applyTransaction({ update: [foundEmployeeRowNode.data] });
    }

    gridApi.current.redrawRows();
    gridApi.current.deselectAll();
  }

  /**
   * Updates the employee master data or the employees applications roles of the selected employees.
   */
  async function onUpdateEmployee(): Promise<void> {
    for (const selectedEmployee of gridApi.current.getSelectedRows()) {
      const foundOldValueIndex: number = context.employees.findIndex(
        (employee: Employee) =>
          employee.employeeId === selectedEmployee.employeeId
      );

      const originEmployeeData: Employee =
        context.employees[foundOldValueIndex];

      /** update application roles and team member standard role **/
      await updateEmployeeRoles(selectedEmployee, originEmployeeData);

      /** updated employee related data **/
      updatedEmployeeMasterData(selectedEmployee);

      gridApi.current.deselectAll();
    }
  }

  /**
   * Updates the application roles, team lead roles and the team member standard role
   *
   * @param selectedEmployee  The employee data from users selection.
   * @param employeeRowValue  The old employee value to compare if a role change was done or not so that the update will
   *                          only be triggered if the user changed the application role or standard role.
   */
  async function updateEmployeeRoles(
    selectedEmployee: Employee,
    employeeRowValue: Employee
  ): Promise<void> {
    if (
      selectedEmployee.genericRoleId !== employeeRowValue.genericRoleId ||
      selectedEmployee.applicationRoles !== employeeRowValue.applicationRoles
    ) {
      const assignRolesParams: AssignRolesPayload = {
        applicationRoles: selectedEmployee.applicationRoles,
        employeeId: selectedEmployee.employeeId,
        tlPrimaryGenericRoles: selectedEmployee.primaryRoles,
        tmGenericRole: selectedEmployee.genericRoleId,
      };

      await updateAccountRole(assignRolesParams);
    }
  }

  /**
   * Updates the master data of the employee which is seen in the more section (contract type, availability, eMail,
   * cost center, rcd number and located in).
   *
   * @param selectedEmployee              The employee data from users selection.
   */
  function updatedEmployeeMasterData(selectedEmployee: Employee): void {
    updateEmployee(selectedEmployee)
      .unwrap()
      .then(() => {
        // if admin is updating him self than it should update the necessary settings in the cached cookie
        // Todo: this should be updated to work with rtk later
        const empObj: Employee = cookies.get("loggedInuser");
        if (selectedEmployee.employeeId === empObj.employeeId) {
          cookies.set("loggedInuser", selectedEmployee);
        }

        message.success("Successfully update employee", 5);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * Clears the filter and updates the table
   */
  function onClearFilter(): void {
    gridApi.current.setFilterModel(null);
    gridApi.current.onFilterChanged();
  }

  /**
   * After the user update values in the show more area than the table should be updated with the new values.
   *
   * @param updateEmployeePayload
   */
  function onUpdateMore(updateEmployeePayload: UpdateEmployeePayload): void {
    const node: RowNode = gridApi.current.getRowNode(
      updateEmployeePayload.selectedEmployee.employeeId.toString()
    );
    node.setData(updateEmployeePayload.selectedEmployee);
  }

  return (
    <>
      <Headline title="Employee data" />

      <div className="filter-download-section">
        <div>
          <Button
            type="primary"
            onClick={() => setShowAddEmployeeModal(true)}
            disabled={isLoading}
            size="large"
          >
            Add employee
          </Button>
        </div>
        <DownloadButton
          path="/reports/export-rpp-report"
          disabled={isLoading}
        />
      </div>
      <div className="button-and-selected">
        <div>
          <Button
            disabled={selectedItems.length === 0}
            onClick={onDeleteEmployee}
            danger
            type="primary"
            size="large"
          >
            Delete Employee
          </Button>
        </div>
        <div className="button-section">
          <ResetFilterButton onClick={onClearFilter} disabled={isLoading} />
          <Button
            disabled={selectedItems.length === 0}
            onClick={onUndo}
            size="large"
          >
            Undo
          </Button>
          <Button
            disabled={selectedItems.length === 0}
            onClick={onUpdateEmployee}
            type="primary"
            size="large"
          >
            Save
          </Button>
        </div>
      </div>

      {modalDeleteVisible && (
        <DeleteEmployeeModal
          modalVisible={modalDeleteVisible}
          setModalVisible={setModalDeleteVisible}
          employeeDeleteStatus={employeeDeleteStatus}
          employeeGridApi={gridApi.current}
          projectLeads={leads?.content.filter((employee) =>
            employee.applicationRoles.includes(ROLES.PROJECT_LEAD)
          )}
          teamLeads={leads?.content.filter((employee) =>
            employee.applicationRoles.includes(ROLES.TEAM_LEAD)
          )}
        />
      )}

      {showMore && (
        <ShowMoreEmployeeDataModal
          modalVisible={showMore}
          setModalVisible={setShowMore}
          employeeData={showMoreEmployeeData}
          onUpdate={onUpdateMore}
        />
      )}

      {showAddEmployeeModal && (
        <EmployeeForm
          modalVisible={showAddEmployeeModal}
          setModalVisible={setShowAddEmployeeModal}
          record={null}
          type="add"
        />
      )}

      <div className="ag-theme-alpine header-white" style={{ height: "100%" }}>
        <AgGridReact
          onSelectionChanged={() =>
            setSelectedItems(gridApi.current.getSelectedRows())
          }
          rowModelType={"serverSide"}
          components={components}
          context={context}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          animateRows={true}
          statusBar={statusBar()}
          cacheBlockSize={30}
          serverSideInfiniteScroll={true}
          onGridSizeChanged={(params: GridSizeChangedEvent) =>
            params.api.sizeColumnsToFit()
          }
        />
      </div>
    </>
  );
};

export default EmployeeDataPage;
