import { EmployeeDeleteStatus } from "@src/types";
import {
  ColDef,
  GetRowIdParams,
  GridOptions,
  RowClassParams,
} from "ag-grid-community";

export const columns: ColDef[] = [
  {
    field: "fullName",
    headerName: "Full name",
  },
  {
    field: "teamLead",
    headerName: "Team lead",
    cellRenderer: "showTeamLeadRenderer",
    menuTabs: [],
    width: 180,
  },
  {
    field: "projectLead",
    headerName: "Project lead",
    cellRenderer: "showProjectLeadRenderer",
    menuTabs: [],
    width: 180,
  },
  {
    width: 10,
    pinned: "right",
    colId: "checkbox",
    checkboxSelection: true,
    suppressFillHandle: true,
    menuTabs: [],
  },
  {
    pinned: "right",
    width: 40,
    minWidth: 20,
    menuTabs: [],
    suppressFillHandle: true,
    cellRenderer: "errorIconRenderer",
    editable: false,
    filterParams: {
      newRowsAction: "keep",
    },
  },
];

export const gridOptions: GridOptions = {
  columnDefs: columns,
  suppressContextMenu: true,
  rowSelection: "multiple",
  rowHeight: 60,
  domLayout: "autoHeight",
  suppressMenuHide: true,
  suppressRowClickSelection: true,
  pagination: true,
  paginationPageSize: 5,
  context: {
    projectLeads: [],
    teamleads: [],
  },
  rowClassRules: {
    "error-row": function (params: RowClassParams) {
      return !!params.node.data.errorMessage;
    },
  },
  getRowId: function (params: GetRowIdParams<EmployeeDeleteStatus>) {
    return params.data.employeeId.toString();
  },
};
