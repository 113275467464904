import { cellClassRules } from "../../../utils/month_cell_renderer_utils";

export const gridOptions = {
  enableRangeSelection: true,
  enableFillHandle: true,
  suppressContextMenu: true,
  rowData: null,
  fillHandleDirection: "x",
};

export const titleRenderer = (params) => {
  let title = params.data.title;
  if (params.data.title.toString().includes("Availability")) {
    title = (
      <div class="availability-cell">
        <label> {params.data.title} </label>
        <label> {params.data.teammember}</label>
      </div>
    );
  }

  return title;
};

export const childrenGridOption = (
  allocation,
  monthName,
  yearMonthKey,
  allocationsKey,
  yearlyAllocations
) => {
  const gridOptions = {
    headerName: monthName,
    year: allocation.year,
    month: allocation.month,
    field: allocationsKey + "." + yearMonthKey,
    colId: `${allocation.year}_${allocation.month}`,
    cellClassRules: cellClassRules,
    menuTabs: [],
    cellClass: (params) => {
      return params.data.title === "your Proposal: "
        ? "editable-cell"
        : "non-editable-cell";
    },
    editable: (params) => {
      params.data.yearlyAllocations = yearlyAllocations;
      return params.node.data.title === "your Proposal: ";
    },
    valueFormatter: (params) => {
      return params.value ? `${params.value}%` : "0%";
    },
  };

  return gridOptions;
};

export const frameworkComponents = {
  titleRenderer: titleRenderer,
};

export const leftPanelSettings = {
  headerName: "",
  field: "title",
  pinned: "left",
  resizable: true,
  minWidth: 250,
  menuTabs: [],
  cellClass: (params) => {
    if (params.data.title === "your Proposal: ") {
      return ["non-editable-cell", "proposal-cell"];
    } else {
      return ["non-editable-cell"];
    }
  },
  cellRenderer: titleRenderer,
};
