import React from "react";

import fixedbottom from "@src/assets/icons/fixedbottom-tech.svg";
import floating from "@src/assets/icons/floating-tech.svg";
import hydrogen from "@src/assets/icons/hydrogen.svg";
import profile from "@src/assets/icons/profile.svg";

import "./project_phase_renderer.less";
import ProjectPhaseChip from "@src/components/project_phase_chip/project_phase_chip";
import { ICellRendererParams } from "ag-grid-community";

const technologyIcons = {
  Floating: floating,
  "Fixed bottom": fixedbottom,
};

interface ProjectPhaseRendererParams {
  params: ICellRendererParams;
}

const ProjectPhaseRenderer: React.FC<ProjectPhaseRendererParams> = ({
  params,
}) => {
  const projectName = params.data.projectName;
  const projectPhase = params.data.projectPhase;
  const technologies = params.data.technologies;
  const projectLeadEmployeeName = params.data.projectLeadEmployeeName;

  function getTechnologyName(technologies: string[]): string {
    if (!technologies) return "";
    return technologies.filter((tech) => tech !== "Hydrogen").join(", ");
  }

  function isHydrogenTechnology(technologies: string[]): boolean {
    if (!technologies) return false;
    return technologies.includes("Hydrogen");
  }

  function getTechnologyIcon(technologies: string[]): string {
    const technologyName = getTechnologyName(technologies);
    return technologyIcons[technologyName] || "";
  }

  return (
    <div className="project-phase">
      <div className="project-phase-info-wrapper">
        <ProjectPhaseChip projectPhase={projectPhase} />
        {getTechnologyIcon(technologies) && (
          <img
            src={getTechnologyIcon(technologies)}
            alt="project phase"
            className="project-phase-technology-icon"
          />
        )}
        <div>
          <span className="project-phase-text">{projectName}</span>
          <div className="project-phase-subtext">
            <span>
              {technologies && `${getTechnologyName(technologies)} ·`}
            </span>
            <img
              src={profile}
              alt="project lead"
              className="project-phase-profile-icon"
            />
            <span>{projectLeadEmployeeName}</span>
          </div>
        </div>
      </div>
      <div>
        {isHydrogenTechnology(technologies) && (
          <img
            src={hydrogen}
            alt="hydrogen"
            className="project-phase-hydrogen-icon"
          />
        )}
      </div>
    </div>
  );
};

export default ProjectPhaseRenderer;
