import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import {
  BasicRoleWithRoleAssignments,
  Employee,
  ProjectRoleRequestsOfStandardRolePayLoad,
  RequestReplies,
  RequestsToSplit,
  StandardRole,
} from "@src/types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const standardRolesApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
    /* POST endpoints */
    ...postEndpoints(builder),
    /* PUT endpoints */
    ...putEndpoints(builder),
    /* DELETE endpoints */
    ...deleteEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    getProjectRoleRequestsOfStandardRole: builder.query<
      RequestReplies[],
      ProjectRoleRequestsOfStandardRolePayLoad
    >({
      query: ({ standardRoleId, showChildRequest, showTimeBlocker }) => {
        return {
          url: `standard-roles/${standardRoleId}/project-role-requests`,
          params: {
            showChildRequest: showChildRequest,
            showTimeBlocker: showTimeBlocker,
          },
        };
      },
      transformErrorResponse: async () => {
        message.error(
          "Failed to get project role requests by standard role id",
          5
        );
      },
      providesTags: ["StandardRoles"],
    }),
    /**
     * Get all standard roles
     */
    getStandardRoles: builder.query<StandardRole[], void>({
      query: () => "standard-roles",
      transformErrorResponse: async () => {
        message.error("Unable to load standard roles", 5);
      },
      providesTags: ["StandardRoles", "AdminStandardRoles"],
    }),
    /**
     * Get all standard roles grouped by their basic role
     */
    getDistinctStandardRoles: builder.query<
      BasicRoleWithRoleAssignments[],
      void
    >({
      query: () => "standard-roles/grouped-by-basic-role",
      transformErrorResponse: async () => {
        message.error("Unable to load distinct standard roles", 5);
      },
      providesTags: ["StandardRoles"],
    }),
    /**
     * Get the team lead of a standard role
     * TODO: check why team-leads is plural
     */
    getTeamLeadOfStandardRole: builder.query<Employee, number>({
      query: (standardRoleId) => `standard-roles/${standardRoleId}/team-leads`,
      transformErrorResponse: async () => {
        message.error("Unable to load team lead of standard role", 5);
      },
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.POST;

  return {
    /**
     * Create a new standard role with the given data
     */
    addStandardRole: builder.mutation<void, StandardRole>({
      query: (standardRole) => ({
        url: "standard-roles",
        method: baseMethod,
        body: standardRole,
      }),
      transformErrorResponse: async () => {
        message.error("Error creating new standardRole", 5);
      },
      invalidatesTags: ["StandardRoles"],
    }),
  };
}

function putEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.PUT;

  return {
    /**
     * Update a standard role with the given data
     */
    updateStandardRole: builder.mutation<void, StandardRole>({
      query: (standardRole) => ({
        url: `standard-roles/${standardRole.standardRoleId}`,
        method: baseMethod,
        body: standardRole,
      }),
      transformErrorResponse: async (response) => {
        if (response.data) {
          message.error(response.data, 5);
        }
        message.error("Error updating standardRole", 5);
      },
      invalidatesTags: ["StandardRoles"],
    }),

    splitRole: builder.mutation<void, RequestsToSplit[]>({
      query: (requestsToSplit) => ({
        url: `standard-roles/split`,
        method: baseMethod,
        body: requestsToSplit,
      }),
      transformErrorResponse: async () => {
        message.error("Error split standardRole", 5);
      },
      invalidatesTags: ["StandardRoles"],
    }),
  };
}

function deleteEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.DELETE;

  return {
    /**
     * Delete a single standard role with a given id
     */
    deleteStandardRole: builder.mutation<void, number>({
      query: (id) => ({
        url: `standard-roles/${id}`,
        method: baseMethod,
      }),
      transformErrorResponse: async () => {
        message.error("Error deleting standard role", 5);
      },
      invalidatesTags: ["StandardRoles"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetStandardRolesQuery,
  useGetDistinctStandardRolesQuery,
  useGetProjectRoleRequestsOfStandardRoleQuery,
  /* Lazy GET endpoints */
  useLazyGetStandardRolesQuery,
  useLazyGetTeamLeadOfStandardRoleQuery,
  useLazyGetProjectRoleRequestsOfStandardRoleQuery,
  /* POST endpoints */
  useAddStandardRoleMutation,
  /* PUT endpoints */
  useUpdateStandardRoleMutation,
  useSplitRoleMutation,
  /* DELETE endpoints */
  useDeleteStandardRoleMutation,
} = standardRolesApi;
