import React from "react";
import "./csv_parse_table_column.less";
import { Button, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { sapDTOStatusEnum } from "@src/utils/helper";

const onInfoClick = (param, gridApi) => {
  let type = "";
  let filterValue = "";

  if (param.data.status === sapDTOStatusEnum.UPDATED) {
    type = "uiNumber";
    filterValue = param.data.uiNumberBoss;
    filter(filterValue, type, gridApi);
  } else if (
    param.data.status === sapDTOStatusEnum.DELETED &&
    param.data.hasTeamleadTeam
  ) {
    type = "uiNumberBoss";
    filterValue = param.data.uiNumber;
    filter(filterValue, type, gridApi);
  }
};

export const infoRenderer = (param, gridApi) => {
  let showToolTip = false;

  // Initialize infoMessage as an array, incorporating existing error messages if any
  let infoMessage = Array.isArray(param.data.errorMessage)
    ? param.data.errorMessage
    : [];

  const hasEntryError = param.data.status === sapDTOStatusEnum.ERROR;
  const isEmployeeTlWithoutRoles =
    param.data.standardRoles?.length === 0 &&
    param.data.isTeamLead &&
    param.data.status === sapDTOStatusEnum.ADDED;

  // Check and add messages based on conditions

  // should verify that if a team lead of the employee is not in the SAP file,
  // the selection for the employee should be disabled
  if (param.data.teamLeadNotInSAPFile) {
    addToInfoMessage(
      infoMessage,
      "Team lead not in SAP file found or flagged for deletion."
    );
  }

  // should verify that if a new team lead is added, the team lead should have standard roles assigned
  if (isEmployeeTlWithoutRoles) {
    addToInfoMessage(
      infoMessage,
      "Please select standard roles for the team lead."
    );
  }

  if (param.data.status === sapDTOStatusEnum.DELETED) {
    // should verify that a project lead can not be deleted if he has projects assigned
    if (param.data.projectDtoList && param.data.projectDtoList.length !== 0) {
      addToInfoMessage(
        infoMessage,
        "Employee has project(s) assigned and cannot be deleted. Please select a new Project lead."
      );
    }

    // should verify that a team lead can not be deleted if he has a team assigned.
    // When clicked on the icon, the team members should be shown
    if (param.data.hasTeamleadTeam) {
      addToInfoMessage(
        infoMessage,
        "The team lead has a team assigned and cannot be deleted. Please click the icon to see the assigned team member."
      );
    }

    // should verify that a team lead can not be deleted if he has roles assigned
    if (param.data.hasEmployeeRolesAssigned) {
      addToInfoMessage(
        infoMessage,
        "Before deletion reassign standard roles of team lead in admin section."
      );
    }
  }

  infoMessage = param.data.errorMessage?.join("\n\n");

  if (infoMessage.length > 0 || hasEntryError) {
    showToolTip = true;
  }

  return (
    showToolTip && (
      <Tooltip
        placement="topLeft"
        title={`${infoMessage}`}
        overlayStyle={{ whiteSpace: "pre-line" }}
      >
        <Button
          onClick={() => onInfoClick(param, gridApi)}
          type="text"
          icon={<InfoCircleOutlined />}
        />
      </Tooltip>
    )
  );
};

const addToInfoMessage = (infoMessageArray, message) => {
  if (!infoMessageArray.includes(message)) {
    infoMessageArray.push(message);
  }
};

export const filter = (status, type, gridApi) => {
  const hardcodedFilter = {
    [type]: { type: "startsWith", filter: status },
  };
  gridApi.current.setFilterModel(hardcodedFilter);
  gridApi.current.onFilterChanged();
};

export const statusRenderer = (param) => {
  let render = <span className={"greyBadge request-update"}>no change</span>;

  switch (param.value) {
    case sapDTOStatusEnum.ERROR:
      render = <span className={"redBadge request-update"}>error</span>;
      break;
    case sapDTOStatusEnum.UPDATED:
      render = <span className={"orangeBadge request-update"}>updated</span>;
      break;
    case sapDTOStatusEnum.DELETED:
      render = <span className={"blackBadge request-update"}>deleted</span>;
      break;
    case sapDTOStatusEnum.ADDED:
      render = <span className={"commentBadge request-update"}>added</span>;
      break;
    default:
  }

  return render;
};

export const detailsRenderer = (
  param,
  setOpenedDetailsSapDTO,
  setShowAddEmployeeModal,
  setShowUpdateModal,
  setShowUpdateProjectleadModal
) => {
  let render = null;

  if (
    param.data.status === sapDTOStatusEnum.ADDED &&
    !param.data.teamleadNotInDualis
  ) {
    render = (
      <Button
        type="link"
        onClick={() => {
          setOpenedDetailsSapDTO(param);
          setShowAddEmployeeModal(true);
        }}
      >
        details
      </Button>
    );
  } else if (
    param.data.status === sapDTOStatusEnum.UPDATED &&
    param.data.oldValues
  ) {
    render = (
      <Button
        type="link"
        onClick={() => {
          setOpenedDetailsSapDTO(param);
          setShowUpdateModal(true);
        }}
      >
        details
      </Button>
    );
  } else if (
    param.data.status === sapDTOStatusEnum.DELETED &&
    param.data.projectDtoList &&
    param.data.projectDtoList.length !== 0
  ) {
    render = (
      <Button
        type="link"
        onClick={() => {
          setOpenedDetailsSapDTO(param);
          setShowUpdateProjectleadModal(true);
        }}
      >
        details
      </Button>
    );
  }

  return render;
};

export const columns = [
  {
    headerName: "Fullname",
    field: "fullName",
    minWidth: 220,
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "UI Number",
    field: "uiNumber",
    minWidth: 150,
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
    editable: false,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "RCD Number",
    field: "rcdNumber",
    minWidth: 200,
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Position ID",
    field: "positionId",
    minWidth: 150,
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
    editable: false,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Org Code",
    field: "departmentCode",
    sortable: true,
    resizable: true,
    minWidth: 150,
    filter: "agTextColumnFilter",
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Application roles",
    field: "applicationRoles",
    sortable: true,
    resizable: true,
    minWidth: 300,
    filter: "agTextColumnFilter",
    valueGetter: function (params) {
      return params.data?.applicationRoles?.map((p) => p.applicationRoleName);
    },
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Teamlead",
    field: "teamLeadFullname",
    sortable: true,
    resizable: true,
    minWidth: 200,
    filter: "agTextColumnFilter",
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Teamlead Ui Number",
    field: "uiNumberBoss",
    sortable: true,
    minWidth: 220,
    filter: "agTextColumnFilter",
    resizable: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Team lead org code",
    field: "teamLeadOrgCode",
    sortable: true,
    minWidth: 220,
    filter: "agTextColumnFilter",
    resizable: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Located",
    field: "located",
    sortable: true,
    minWidth: 150,
    filter: "agTextColumnFilter",
    resizable: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "E-Mail",
    field: "email",
    minWidth: 250,
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Status",
    field: "status",
    sortable: true,
    pinned: "right",
    minWidth: 150,
    filter: "agTextColumnFilter",
    cellRenderer: "statusRenderer",
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    field: "details",
    pinned: "right",
    minWidth: 125,
    suppressMenu: true,
    cellRenderer: "detailsRenderer",
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    minWidth: 50,
    pinned: "right",
    colKey: "checkbox",
    checkboxSelection: true,
    menuTabs: [],
    disabled: true,
    cellClass: "ag-grid-cell no-border",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    colKey: "notInDualis",
    pinned: "right",
    minWidth: 60,
    menuTabs: [],
    cellRenderer: "infoRenderer",
    filterParams: {
      newRowsAction: "keep",
    },
  },
];

export const gridOptions = {
  columnDefs: [...columns],
  suppressContextMenu: true,
  floatingFilter: true,
  rowSelection: "multiple",
  rowHeight: 60,
  domLayout: "autoHeight",
  suppressMenuHide: true,
  suppressRowClickSelection: true,
  pagination: true,
  paginationPageSize: 10,
};
