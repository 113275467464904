import { FilterDropdown } from "../../../../components/filter_dropdown";
import { FilterGroup } from "../../../../components/filter_group";
import React, { useMemo } from "react";
import { mapToTreeData } from "../../../table_filtering/utils/filter_utils";

const RegionAssignmentFilter = ({ gridApi, disabled, context }) => {
  let clearFilterCountries = null;
  let clearFilterRegions = null;

  const countriesOptions = useMemo(() => {
    return mapToTreeData(context.countries, "countryName");
  }, [context.countries]);

  const regionsOptions = useMemo(() => {
    return mapToTreeData(context.regions, "regionName");
  }, [context.regions]);

  const onChangeQuickFilter = (searchText) => {
    gridApi.current.setQuickFilter(searchText);
  };
  const onClickResetFilter = () => {
    gridApi.current.setFilterModel(null);
    gridApi.current.setQuickFilter("");
    clearFilterCountries?.();
    clearFilterRegions?.();
  };

  const assignResetFunctionCountries = (resetFilterFunction) => {
    clearFilterCountries = resetFilterFunction;
  };

  const assignResetFunctionRegions = (resetFilterFunction) => {
    clearFilterRegions = resetFilterFunction;
  };

  const onCountryFilterChange = (values, field) => {
    gridApi.current.getFilterInstance(field).setModel({ values: values });
    gridApi.current.onFilterChanged();
  };

  const onRegionFilterChange = (values, field) => {
    gridApi.current.getFilterInstance(field).setModel({ values: values });
    gridApi.current.onFilterChanged();
  };

  return (
    <div className="essential-filter-container">
      <div className="filter-dropdowns">
        {countriesOptions?.length > 0 && (
          <FilterDropdown
            placeholderDropdown="Country Filter"
            placeholderSearch="Enter Country"
            treeData={countriesOptions}
            onFilterChange={(values) =>
              onCountryFilterChange(values, "countryName")
            }
            assignResetFunction={assignResetFunctionCountries}
            disabled={disabled}
          />
        )}
        <FilterDropdown
          placeholderDropdown="Region Filter"
          placeholderSearch="Enter Region"
          treeData={regionsOptions}
          onFilterChange={(values) =>
            onRegionFilterChange(values, "regionName")
          }
          assignResetFunction={assignResetFunctionRegions}
          disabled={disabled}
        />
      </div>
      <FilterGroup
        onFilterQuickSearch={onChangeQuickFilter}
        onReset={onClickResetFilter}
        data-testid="filterGroup"
        disabled={disabled}
      />
    </div>
  );
};

export default RegionAssignmentFilter;
